<!-- Add new registry panel -->
<app-modal
    [saveBtnText]="'Add'"
    *ngIf="viewRegistryPanel"
    [(visible)]="viewRegistryPanel"
    [header]="'Add Registry'"
    [context]="this"
    [saveFunc]="addRegistry"
    [closeFunc]="cancelNewRegistry"
    [modalWidth]="'40vw'"
    [modalHeight]="'70vh'"
    [saveBtnDisabled]="!nuForm.valid"
>
    <div class="container-fluid" [formGroup]="registryForm" #nuForm="ngForm">
        <div class="form-group row mt-2">
            <div class="col">
                <input
                    aria-label="Registry Name"
                    placeholder="Registry Name (e.g. NKF Patient Network)"
                    type="text"
                    class="form-control"
                    id="registryName"
                    formControlName="registryName"
                />
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <input
                    aria-label="Registry Key"
                    placeholder="Registry Key (e.g. nkfpatientnetwork)"
                    type="text"
                    class="form-control"
                    id="registryKey"
                    formControlName="registryKey"
                />
                <div
                    *ngIf="
                        registryForm.controls.registryKey.errors &&
                        (registryForm.controls.registryKey.dirty ||
                            registryForm.controls.registryKey.touched)
                    "
                    class="formError text-danger"
                >
                    Registry Key must be only lowercase, number and hyphen.
                </div>
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <input
                    aria-label="Default Site Name"
                    placeholder="Default Site Name (e.g. Geisinger)"
                    type="text"
                    class="form-control"
                    id="siteLabel"
                    formControlName="siteLabel"
                />
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <input
                    aria-label="Site Key"
                    placeholder="Site Key (e.g. geisinger)"
                    type="text"
                    class="form-control"
                    id="siteKey"
                    formControlName="siteKey"
                />
                <div
                    *ngIf="
                        registryForm.controls.siteKey.invalid &&
                        (registryForm.controls.siteKey.dirty ||
                            registryForm.controls.siteKey.touched)
                    "
                    class="formError text-danger"
                >
                    Site Key must be only lowercase and number.
                </div>
            </div>
            <div class="formError text-muted">You can add additional site information later.</div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <select
                    class="form-control"
                    aria-label="registryTypeID"
                    formControlName="registryTypeID"
                    id="registryTypeID"
                    required
                >
                    <option value="" disabled>Registry Type --Please select--</option>
                    <option
                        *ngFor="let registryType of registryTypes"
                        [value]="registryType.id"
                        [selected]="registryType.id === registryForm.value.registryTypeID"
                    >
                        {{ registryType.description }}
                    </option>
                </select>
                <div
                    *ngIf="
                        registryForm.controls.registryTypeID.errors?.required &&
                        (registryForm.controls.registryTypeID.dirty ||
                            registryForm.controls.registryTypeID.touched)
                    "
                    class="formError text-danger"
                >
                    Please select a registry type
                </div>
            </div>
        </div>
    </div>
</app-modal>

<!-- Add new site panel -->
<app-modal
    [saveBtnText]="'Add'"
    *ngIf="viewSitePanel"
    [(visible)]="viewSitePanel"
    [header]="'Add New Site'"
    [context]="this"
    [saveFunc]="addSite"
    [closeFunc]="cancelNewSite"
    [modalWidth]="'40vw'"
    [modalHeight]="'70vh'"
    [saveBtnDisabled]="!nuForm.valid || loading"
>
    <div class="container-fluid" [formGroup]="siteForm" #nuForm="ngForm">
        <div class="form-group row mt-2">
            <div class="col">
                <select
                    class="form-select"
                    aria-label="registry_ID"
                    formControlName="registry_ID"
                    id="registry_ID"
                    required
                >
                    <option value="" disabled>Registry --Please select--</option>
                    <option
                        *ngFor="let reg of registryKeys"
                        [value]="reg.registry_ID"
                        [selected]="reg.registry_ID === siteForm.value.registry_ID"
                    >
                        {{ reg.registry_key }}
                    </option>
                </select>
                <div
                    *ngIf="
                        siteForm.controls.registry_ID.errors?.required &&
                        (siteForm.controls.registry_ID.dirty ||
                            siteForm.controls.registry_ID.touched)
                    "
                    class="formError text-danger"
                >
                    Please select a registry
                </div>
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col">
                <input
                    aria-label="Site Name"
                    placeholder="Site Name (e.g. Geisinger)"
                    type="text"
                    class="form-control"
                    id="siteLabel"
                    formControlName="siteLabel"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteLabel.invalid &&
                        (siteForm.controls.siteLabel.dirty || siteForm.controls.siteLabel.touched)
                    "
                >
                    <div
                        *ngIf="siteForm.controls.siteLabel.errors?.required"
                        class="formError text-danger"
                    >
                        Please enter a site name
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col">
                <input
                    aria-label="Site Key"
                    placeholder="Site Key (e.g. geisinger)"
                    type="text"
                    class="form-control"
                    id="siteKey"
                    formControlName="siteKey"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteKey.invalid &&
                        (siteForm.controls.siteKey.dirty || siteForm.controls.siteKey.touched)
                    "
                    class="formError text-danger"
                >
                    Site Key must be only lowercase, number and hyphen.
                </div>
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col me-1">
                <input
                    aria-label="Site Street Address"
                    placeholder="Site Street Address"
                    type="text"
                    class="form-control"
                    id="siteStreetAddress"
                    formControlName="siteStreetAddress"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteStreetAddress.invalid &&
                        (siteForm.controls.siteStreetAddress.dirty ||
                            siteForm.controls.siteStreetAddress.touched)
                    "
                    class="formError text-danger"
                >
                    Invalid Site Street Address.
                </div>
            </div>
            <div class="col ms-1">
                <input
                    aria-label="Site Street Address 2"
                    placeholder="Site Street Address 2"
                    type="text"
                    class="form-control"
                    id="siteStreetAddress2"
                    formControlName="siteStreetAddress2"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteStreetAddress2.invalid &&
                        (siteForm.controls.siteStreetAddress2.dirty ||
                            siteForm.controls.siteStreetAddress2.touched)
                    "
                    class="formError text-danger"
                >
                    Invalid Site Street Address.
                </div>
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col me-1">
                <select
                    aria-label="Site Country"
                    placeholder="Site Country"
                    type="text"
                    class="form-select"
                    id="siteCountry"
                    formControlName="siteCountry"
                >
                    <option value="" selected disabled>Country - Please Select One</option>
                    <option value="Canada">Canada</option>
                    <option value="United States of America">United States of America</option>
                    <option value="Germany">Germany</option>
                </select>
                <div
                    *ngIf="
                        siteForm.controls.siteCountry.invalid &&
                        (siteForm.controls.siteCountry.dirty ||
                            siteForm.controls.siteCountry.touched)
                    "
                    class="formError text-danger"
                >
                    Invalid Site Country.
                </div>
            </div>
            <div class="col ms-1">
                <input
                    aria-label="Site State/Province"
                    placeholder="Site State/Province"
                    type="text"
                    class="form-control"
                    id="siteStateProvince"
                    formControlName="siteStateProvince"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteStateProvince.invalid &&
                        (siteForm.controls.siteStateProvince.dirty ||
                            siteForm.controls.siteStateProvince.touched)
                    "
                    class="formError text-danger"
                >
                    Invalid Site State or Province.
                </div>
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col me-1">
                <input
                    aria-label="Site City"
                    placeholder="Site City"
                    type="text"
                    class="form-control"
                    id="siteCity"
                    formControlName="siteCity"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteCity.invalid &&
                        (siteForm.controls.siteCity.dirty || siteForm.controls.siteCity.touched)
                    "
                    class="formError text-danger"
                >
                    Invalid Site City.
                </div>
            </div>
            <div class="col ms-1">
                <input
                    aria-label="Site Zip"
                    placeholder="Site Zip"
                    type="text"
                    class="form-control"
                    id="siteZip"
                    formControlName="siteZip"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteZip.invalid &&
                        (siteForm.controls.siteZip.dirty || siteForm.controls.siteZip.touched)
                    "
                    class="formError text-danger"
                >
                    Invalid Site Zip.
                </div>
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col me-1">
                <input
                    aria-label="Site County/Municipality"
                    placeholder="Site County/Municipality"
                    type="text"
                    class="form-control"
                    id="siteCounty"
                    formControlName="siteCounty"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteCounty.invalid &&
                        (siteForm.controls.siteCounty.dirty || siteForm.controls.siteCounty.touched)
                    "
                    class="formError text-danger"
                >
                    Invalid Site County/Municipality.
                </div>
            </div>
            <div class="col ms-1">
                <input
                    aria-label="Site Work Phone"
                    placeholder="Site Work Phone"
                    type="text"
                    class="form-control"
                    id="siteWorkPhone"
                    formControlName="siteWorkPhone"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteWorkPhone.invalid &&
                        (siteForm.controls.siteWorkPhone.dirty ||
                            siteForm.controls.siteWorkPhone.touched)
                    "
                    class="formError text-danger"
                >
                    Invalid Site Work Phone.
                </div>
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col">
                <select
                    class="form-select"
                    aria-label="siteTypeId"
                    formControlName="siteTypeId"
                    id="siteTypeId"
                >
                    <option value="" [selected]="siteForm.value.siteTypeId === ''" disabled>
                        Site Type --Please select--
                    </option>
                    <option
                        *ngFor="let siteType of siteTypes"
                        [value]="siteType.id"
                        [selected]="siteType.id === siteForm.value.siteTypeId"
                    >
                        {{ siteType.type }}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col">
                <input
                    aria-label="Site URL"
                    placeholder="Site URL"
                    type="url"
                    class="form-control"
                    id="siteUrl"
                    formControlName="siteUrl"
                />
                <div
                    *ngIf="
                        siteForm.controls.siteUrl.invalid &&
                        (siteForm.controls.siteUrl.dirty || siteForm.controls.siteUrl.touched)
                    "
                    class="formError text-danger"
                >
                    Invalid Site URL.
                </div>
            </div>
        </div>
        <div class="formError text-muted">
            You can copy the configs from another site using 'COPY SITE CONFIG' later.
        </div>
    </div>
</app-modal>

<!-- Registry and Site list table -->
<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body data-table-ctrl-992">
                    <div>
                        <h1>Registry & Site</h1>
                        <app-loading-component [loading]="loading" [text]="'loading'">
                            <div class="ml-3 mt-2 d-flex">
                                <app-button
                                    *ngIf="showAddEdit"
                                    class="mx-3"
                                    (action)="newRegistry()"
                                    [buttonText]="'Registry.AddNewRegistry'"
                                />
                                <app-button
                                    *ngIf="showAddEdit"
                                    class="mx-3"
                                    (action)="newSite()"
                                    [buttonText]="'Registry.AddNewSite'"
                                />
                                <div *ngIf="message" class="text-center text-danger mx-3">
                                    {{ message }}
                                </div>
                            </div>
                            <div *ngIf="deployResultMsgList.length !== 0" class="text-center mb-2">
                                Deploy Result
                                <div *ngFor="let msg of deployResultMsgList">
                                    {{ msg }}
                                    <br />
                                </div>
                            </div>
                        </app-loading-component>
                        <div class="d-flex justify-content-end">
                            <div class="ml-1 d-flex">
                                <app-button
                                    [iconConfig]="{ iconClasses: 'fa-regular fa-sliders-v' }"
                                    [buttonClasses]="'ml-1'"
                                    style="opacity: 0.5; cursor: default"
                                    (action)="showToast()"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <small class="text-muted">
                            * DEPLOY DB: Deploy registry and site data in DB to different Env<br />
                            * COPY SITE CONFIG: Copy site configurations from another site in the
                            same registry<br />
                            * DEPLOY SITE CONFIG: Deploy site configurations to different env<br />
                        </small>
                    </div>
                    <app-loading-component [loading]="loadingTable" [text]="'loading'">
                        <app-table-component
                            [rowData]="registries"
                            [columnDefs]="colDefs"
                            pagination="true"
                            [filterModelKey]="'registry_site'"
                            [showFilterRow]="false"
                    >
                        </app-table-component>
                    </app-loading-component>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Copy site config panel -->
<app-modal
    [saveBtnText]="'Submit'"
    *ngIf="viewCopySiteConfigPanel"
    [(visible)]="viewCopySiteConfigPanel"
    [header]="'Copy Site Configs'"
    [context]="this"
    [saveFunc]="submitCopySiteConfig"
    [closeFunc]="cancelCopySiteConfig"
    [modalWidth]="'40vw'"
    [modalHeight]="'70vh'"
    [saveBtnDisabled]="!csForm.valid"
>
    <div class="container-fluid" [formGroup]="copySiteConfigForm" #csForm="ngForm">
        <div class="formError text-muted">
            Copy the configs of the selected table(s) from the source site to the target site.
        </div>
        <div class="form-group row mt-2">
            <div class="col me-1">
                <label>Registry: </label>
                {{ copyRegistryKey }}
            </div>
            <div class="col ms-1">
                <label>Target Site: </label>
                {{ copyDestSiteKey }}
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col">
                <label>Source Site: </label>
                <select
                    class="form-select"
                    aria-label="sourceSite"
                    formControlName="sourceSite"
                    id="sourceSite"
                    required
                >
                    <option value="" disabled>-- Please select --</option>
                    <option
                        *ngFor="let site of copySourceSites"
                        [value]="site.site_name"
                        [selected]="site.site_name === copySiteConfigForm.value.sourceSite"
                    >
                        {{ site.site_name }}
                    </option>
                </select>
                <div
                    *ngIf="
                        copySiteConfigForm.controls.sourceSite.errors?.required &&
                        (copySiteConfigForm.controls.sourceSite.dirty ||
                            copySiteConfigForm.controls.sourceSite.touched)
                    "
                    class="formError text-danger"
                >
                    Please select a source site
                </div>
            </div>
        </div>
        <app-loading-component [loading]="loading_configTables" [text]="'loading'">
            <div class="form-group row mt-2">
                <div *ngIf="configTables && configTables.length !== 0" class="col">
                    <label>Select tables you want to copy: </label>
                    <div *ngFor="let item of configTables">
                        <input
                            type="checkbox"
                            class="mx-3"
                            [checked]=""
                            (change)="checkConfigTable(item.tableKey, $event.target.checked)"
                        />
                        {{ item.tableKey }}
                        <div *ngIf="item.tableKey === 'consent_config'">
                            <input
                                type="text"
                                placeholder="Consent Envelope ID"
                                (change)="newConsentEnvelopeId = $event.target.value"
                            />
                            <input
                                type="text"
                                placeholder="Consent Name"
                                (change)="newConsentName = $event.target.value"
                            />
                            <div class="formError text-muted">
                                If you don't enter the new consent, it will be copied from the
                                source config.
                            </div>
                        </div>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            class="mx-3"
                            [checked]=""
                            (change)="checkConfigTable('task_prototype', $event.target.checked)"
                        />
                        task_prototype (RDS)
                    </div>
                </div>
            </div>
        </app-loading-component>
        <div *ngIf="copyMessage" class="text-center text-danger mx-3">
            {{ copyMessage }}
        </div>
    </div>
</app-modal>
<!-- Deploy site config panel -->
<app-modal
    [saveBtnText]="'Submit'"
    *ngIf="viewDeploySiteConfigPanel"
    [(visible)]="viewDeploySiteConfigPanel"
    [header]="'Deploy Site Configs'"
    [context]="this"
    [saveFunc]="submitDeploySiteConfig"
    [closeFunc]="cancelDeploySiteConfig"
    [modalWidth]="'40vw'"
    [modalHeight]="'70vh'"
    [saveBtnDisabled]="!deploySiteConfigForm.valid || !selectedIsValid || submittingSiteConfigs"
>
    <div class="container-fluid" [formGroup]="deploySiteConfigForm">
        <div class="formError text-muted">
            Deploy the configs of the selected table to the destination env.
        </div>
        <div class="form-group row mt-2">
            <div class="col me-1">
                <label>Registry: </label>
                {{ deployRegistryKey }}
            </div>
            <div class="col ms-1">
                <label>Site: </label>
                {{ deploySiteKey }}
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col me-1">
                <label>Selected Env: </label>
                {{ targetEnv }}
            </div>
            <div class="col ms-1">
                <label>Deploy Destination Env: </label>
                <select
                    class="form-select"
                    aria-label="siteConfigDeployEnv"
                    formControlName="siteConfigDeployEnv"
                    id="siteConfigDeployEnv"
                    required
                >
                    <option
                        *ngFor="let env of targetEnvs"
                        [value]="env"
                        [selected]="env === siteConfigDeployEnv"
                    >
                        {{ env }}
                    </option>
                </select>
                <div
                    *ngIf="
                        deploySiteConfigForm.controls.siteConfigDeployEnv.errors?.required &&
                        (deploySiteConfigForm.controls.siteConfigDeployEnv.dirty ||
                            deploySiteConfigForm.controls.siteConfigDeployEnv.touched)
                    "
                    class="formError text-danger"
                >
                    Please select a destination env
                </div>
            </div>
        </div>
        <app-loading-component [loading]="loading_configTables" [text]="'loading'">
            <div class="form-group row mt-2">
                <div *ngIf="configTables && configTables.length !== 0" class="col">
                    <label>Select tables you want to deploy: </label>
                    <div *ngFor="let item of configTables">
                        <input
                            type="checkbox"
                            class="mx-3"
                            [checked]=""
                            (change)="checkConfigTable(item.tableKey, $event.target.checked)"
                        />
                        {{ item.tableKey }}
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            class="mx-3"
                            [checked]=""
                            (change)="checkConfigTable('task_prototype', $event.target.checked)"
                        />
                        task_prototype (RDS table)
                    </div>
                </div>
            </div>
        </app-loading-component>
        <div *ngIf="copyMessage" class="text-center text-danger mx-3">
            {{ copyMessage }}
        </div>
        <app-loading-component
            [loading]="loading_copyDeployConfig"
            [text]="'loading'"
        ></app-loading-component>
    </div>
</app-modal>
<!-- Deploy Registry config panel -->
<app-modal
    [saveBtnText]="'Submit'"
    *ngIf="viewDeployRegistryConfigPanel"
    [(visible)]="viewDeployRegistryConfigPanel"
    [header]="'Deploy Registry Configs'"
    [context]="this"
    [closeFunc]="cancelDeployRegistryConfig"
    [modalWidth]="'40vw'"
    [modalHeight]="'70vh'"
    [saveFunc]="submitDeployRegistryConfig"
    [saveBtnDisabled]="!deployRegistryConfigForm.valid"
>
    <div class="container-fluid" [formGroup]="deployRegistryConfigForm">
        <div class="formError text-muted">
            Deploy the configs of the selected Registry to the destination env.
        </div>
        <div class="form-group row mt-2">
            <div class="col me-1">
                <label>Registry: </label>
                {{ deployRegistryKey }}
            </div>
            <div class="col ms-1">
                <label>Site: </label>
                {{ deploySiteKey }}
            </div>
            <div class="form-group row mt-2">
                <div class="col me-1">
                    <label>Selected Env: </label>
                    {{ targetEnv }}
                </div>
                <div class="col ms-1">
                    <label>Deploy Destination Env: </label>
                    <select
                        class="form-select"
                        aria-label="siteConfigDeployEnv"
                        formControlName="siteConfigDeployEnv"
                        id="siteConfigDeployEnv"
                        required
                    >
                        <option
                            *ngFor="let env of targetEnvs"
                            [value]="env"
                            [selected]="env === siteConfigDeployEnv"
                        >
                            {{ env }}
                        </option>
                    </select>
                  
                </div>
            </div>
        </div>
    </div>
</app-modal>

const lambdaEndpoint = 'https://hpg4nqn8i7.execute-api.ca-central-1.amazonaws.com/Prod/';

export const environment = {
    production: true,
    development: false,
    captchaOn: true,
    awsConfig: {
        Auth: {
            identityPoolId: '',
            region: '',
            userPoolId: '',
            userPoolWebClientId: '',
            mandatorySignIn: true,
            cookieStorage: {
                secure: true,
            },
            storage: sessionStorage,
            authenticationFlowType: 'USER_PASSWORD_AUTH',
            oauth: {
                domain: 'nkfpatientnetwork.prod.healthie.net/login',
                scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: 'https://nkfpatientnetwork.prod.healthie.net/',
                redirectSignOut: 'https://nkfpatientnetwork.prod.healthie.net/logout/',
                responseType: 'code',
            },
        },
        API: {
            aws_web_assets: 'h20-live-web-assets-ca-central-1.s3.amazonaws.com',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'application/json',
                Accept: 'application/json, text/plain',
            },
            createH20Account: 'NA',
            getConsentDoc: 'NA',
            writeToBucketURL: 'NA',
            writeToBucketKey: 'NA',
            readFromBucketKey: 'NA',
            listBucketItemsKey: 'NA',
            readFromBucketURL: 'NA',
            listBucketItemsURL: 'NA',
            setExternalIdentifierURL: 'NA',
            writeToDb: 'NA',
            writeToDbKey: 'NA',
            userServiceProvider: 'NA',
            writeToDBUser: 'NA',
            writeToDBUserAttribute: 'NA',
            writeToDBSurvey: lambdaEndpoint + 'WriteToDBSurvey',
            writeToDBConsent: 'NA',
            writeToDBActivationStatus: 'NA',
            writeToDBTask: lambdaEndpoint + 'WriteToDBTask',
            writeToDBPatient: 'NA',
            writeToDBInfo: 'NA',
            sendEmailFailedLogin: 'NA',
            writeToDBSite: lambdaEndpoint + 'WriteToDBSite',
            writeToDBClaim: 'NA',
            sendAdminNotification: 'NA',
            getExportData: 'NA',
            getDataExportList: 'NA',
            registerUser: 'NA',
            userSettingsService: 'NA',
            writeToDBCaseReview: 'NA',
            scoringSubmission: 'NA',
            writeFileToBucket: 'NA',
            submissionTriggers: 'NA',
            hardDeletePatient: 'NA',
            writeToDBClinicalData: 'NA',
            getToken: 'NA',
            sendEmailTemplate: 'NA',
            updateParticipantStatus: 'NA',
            writeToDBLog: 'NA',
            writeToDBTrackers: 'NA',
            docusignHandler: 'NA',
            DDBEmailTemplates: 'NA',
            registryConfiguration: lambdaEndpoint + 'RegistryConfiguration',
            writeToDBRegistry: lambdaEndpoint + 'WriteToDBRegistry',
            emailMapping: 'NA',
            scheduleConfiguration: 'NA',
            deployManagement: lambdaEndpoint + 'DeployManagement',
            writeToDBDashboard: lambdaEndpoint + 'WriteToDBDashboard',
            anonymousSurveyHandler: 'NA',
            getWithdrawnData: 'NA',
            layoutConfiguration: 'NA',
            clinicalScreenerHandler: 'NA',
            choicesFromURLWriter: 'NA',
            choicesFromURLHandler: 'NA',
            dashboardConfiguration: lambdaEndpoint + 'DashboardConfiguration',
            surveySessionHandler: 'NA',
            greenlightHandler: 'NA',
            sendAdminNotificationPrtRegister: 'NA',
            listBucketItemsComposer: lambdaEndpoint + 'ListBucketItemsComposer',
            readFromBucketComposer: lambdaEndpoint + 'ReadFromBucketComposer',
            vocabularyConfiguration: lambdaEndpoint + 'VocabularyConfiguration',
            surveyQuestionKeyMappingHandler: lambdaEndpoint + 'SurveyQuestionKeyMappingHandler',
            surveySubmissionHandler: lambdaEndpoint + 'SurveySubmissionHandler',
            getSurveySubmission: lambdaEndpoint + 'GetSurveySubmission',
        },
        Storage: {
            AWSS3: {
                deployed_ui_bucket: 's3-933461011020-live-website',
                bucket: 'h20-live-data-ca-central-1',
                region: 'ca-central-1',
            },
        },
        Path: {
            defaultWebAssetPath: 'https://h20-live-web-assets-ca-central-1.s3.amazonaws.com',
            liveWebAssetPath: 'https://h20-live-web-assets-ca-central-1.s3.amazonaws.com',
        },
    },
};

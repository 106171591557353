import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { CustomCellRendererComponent } from './cell-renderer/custom-cell-renderer/custom-cell-renderer.component';
import { TranslateService } from '@ngx-translate/core';

const customDataTypes = [
    'icon',
    'actionBtn',
    'openAndActionBtns',
    'textWithIcon',
    'dropdown',
    'iconBtns',
];

@Component({
    selector: 'app-table-component',
    templateUrl: './table-component.component.html',
    styleUrls: ['./table-component.component.scss'],
})
export class TableComponent {
    gridApi: GridApi;
    gridColumnApi: any;
    @Input() columnDefs: ColDef;
    @Input() rowData: Array<unknown>;
    @Input() pagination: boolean = false;
    @Input() paginationPageSize: number = 10;
    @Input() rowSelection?: string = '';
    @Input() suppressRowClickSelection: boolean = false

    @Input() showFilterRow = true;
    @Output() selectionChanged?: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() getGridApi?: EventEmitter<GridApi> = new EventEmitter<GridApi>()
    @Input() filterModelKey: string;

    constructor(public translate: TranslateService) {
        translate.onLangChange.subscribe(() => {
            this.gridApi?.refreshCells?.();
        });
    }

    public defaultColDef: ColDef = {
        suppressSizeToFit: false,
        resizable: true,
        autoHeight: true,
        floatingFilter: true,
        wrapText: true,
        filterParams: {
            filterOptions: ['contains'],
            maxNumConditions: 1,
            buttons: ['clear'],
            closeOnApply: true,
        },
        cellStyle: {
            height: 60,
        },
        cellRendererSelector: (params) => {
            if (customDataTypes?.includes(params?.colDef?.cellDataType?.toString())) {
                return {
                    component: CustomCellRendererComponent,
                    params: {
                        componentType: params.colDef?.cellDataType,
                        additionalParams: params.colDef?.cellRendererParams(params),
                    },
                };
            } else return undefined;
        },
    };
    ngOnInit() {
        this.defaultColDef = {
            suppressSizeToFit: false,
            resizable: false,
            autoHeight: true,
            unSortIcon: true,
            floatingFilter: this.showFilterRow,
            wrapText: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['clear'],
                closeOnApply: true,
            },
            cellStyle: {
                height: 60,
            },
            cellRendererSelector: (params) => {
                if (customDataTypes?.includes(params?.colDef?.cellDataType?.toString())) {
                    return {
                        component: CustomCellRendererComponent,
                        params: {
                            componentType: params.colDef?.cellDataType,
                            additionalParams: params.colDef?.cellRendererParams(params),
                        },
                    };
                } else return undefined;
            },
        };
    }

    public dataTypeDefinitions = {
        icon: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        actionBtn: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        openAndActionBtns: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        textWithIcon: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        iconBtns: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        dropdown: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
    };

    public paginationPageSizeSelector = [10, 25, 50, 100];

    onReady = (event) => {
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
        this.getGridApi?.emit(event.api);
        setTimeout(() => {
            event.api.sizeColumnsToFit();
        }, 0);
    };

    onSelect = (event) => {
        this.selectionChanged?.emit(event);
    };

    onFilterChanged(params) {
        const filterModel = params.api.getFilterModel();
        const allFilterModels = JSON.parse(localStorage.getItem('filterModels')) || {};
        allFilterModels[this.filterModelKey] = filterModel;
        localStorage.setItem('filterModels', JSON.stringify(allFilterModels));
        this.manageCustomFilterVisibility(params);
    }

    onFirstDataRendered(params) {
        const allFilterModels = JSON.parse(localStorage.getItem('filterModels')) || {};
        const filterModel = allFilterModels[this.filterModelKey];
        if (filterModel) {
            params.api.setFilterModel(filterModel);
        }
        this.manageCustomFilterVisibility(params);
    }

    manageCustomFilterVisibility(params) {
        params.api.getColumnFilterInstance('yourColumnField', (filterComponent) => {
            if (filterComponent) {
                filterComponent.afterGuiAttached({ api: params.api });
                params.api.onFilterChanged();
            }
        });
    }
}

<app-modal
    [saveBtnText]="hasCreateUser ? 'Create User' : 'Edit User'"
    *ngIf="viewNewUserPanel"
    [(visible)]="viewNewUserPanel"
    [header]="hasCreateUser ? 'Create new user' : 'Edit user'"
    [context]="this"
    [saveFunc]="hasCreateUser ? saveNewUser : saveEditUser"
    [closeFunc]="cancelNewUser"
    [modalWidth]="'40vw'"
    [saveBtnDisabled]="!nuForm.valid || loading"
    [modalHeight]="'60vh'"
>
    <div class="card-body" [formGroup]="newUserForm" #nuForm="ngForm">
        <div class="container-fluid">
            <div class="form-group row mt-2">
                <div class="col">
                    <input
                        aria-label="Name"
                        placeholder="Name"
                        type="text"
                        class="form-control"
                        id="nickname"
                        formControlName="nickname"
                    />
                    <div
                        *ngIf="
                            newUserForm.controls.nickname.invalid &&
                            (newUserForm.controls.nickname.dirty ||
                                newUserForm.controls.nickname.touched)
                        "
                    >
                        <div
                            *ngIf="newUserForm.controls.nickname.errors?.required"
                            class="formError text-danger"
                        >
                            Please enter a name
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row mt-2">
                <div class="col">
                    <input
                        [readonly]="!hasCreateUser"
                        aria-label="Email"
                        placeholder="Email"
                        id="email"
                        type="email"
                        class="form-control"
                        formControlName="username"
                        required
                    />
                    <div
                        *ngIf="
                            newUserForm.controls.username.invalid &&
                            (newUserForm.controls.username.dirty ||
                                newUserForm.controls.username.touched)
                        "
                    >
                        <div
                            *ngIf="newUserForm.controls.username.errors?.required"
                            class="formError text-danger"
                        >
                            Please enter an email. This is the username.
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="hasCreateUser" class="form-group row mt-2">
                <div class="col">
                    <input
                        aria-label="Password"
                        placeholder="Password"
                        type="password"
                        class="form-control"
                        formControlName="password"
                    />
                    <div
                        *ngIf="
                            newUserForm.controls.password.errors &&
                            newUserForm.controls.password.dirty
                        "
                        class="formError text-danger"
                    >
                        Password must be at least 8 characters long, and contain uppercase,
                        lowercase, special character and a number
                    </div>
                </div>
                <div class="col">
                    <input
                        aria-label="Confirm password"
                        placeholder="Confirm password"
                        type="password"
                        class="form-control"
                        autocomplete="off"
                        [value]="confirm_password"
                        formControlName="confirmPassword"
                        [class.unmatched]="newUserForm.controls.confirmPassword.errors"
                        #confirm_password
                    />
                    <div
                        *ngIf="
                            newUserForm.controls.confirmPassword.errors &&
                            newUserForm.controls.confirmPassword.dirty
                        "
                        class="formError text-danger"
                    >
                        Does not match
                    </div>
                </div>
            </div>
            <div
                *ngIf="
                    userClaims &&
                    (userClaims.usersCreateSelectRoleRegistry ||
                        userClaims.usersCreateSelectRoleAdmin)
                "
                class="form-group row mt-2"
            >
                <div class="col">
                    <select
                        class="form-control"
                        aria-label="Role"
                        formControlName="role"
                        id="role"
                        required
                    >
                        <option value="" disabled>Role --Please select--</option>
                        <option value="composer">composer</option>
                        <option value="composer-admin">composer-admin</option>
                    </select>
                    <div
                        *ngIf="
                            newUserForm.controls.role.errors?.required &&
                            (newUserForm.controls.role.dirty || newUserForm.controls.role.touched)
                        "
                        class="formError text-danger"
                    >
                        Please select a role
                    </div>
                </div>
                <div *ngIf="newUserForm.value.role === 'site-admin'" class="col">
                    <select
                        class="form-control"
                        aria-label="Site"
                        id="siteid"
                        formControlName="siteid"
                    >
                        <option value="" disabled>Site --Please select--</option>
                        <option
                            *ngFor="let site of managed_sites"
                            [value]="site.name || site"
                            [selected]="
                                site.name === newUserForm.value.siteid ||
                                site === newUserForm.value.siteid
                            "
                        >
                            {{ site.label || site }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <h4 *ngIf="createUserError" class="badge badge-warning text-wrap">
                        Error: {{ createUserErrorMessage }}
                    </h4>
                </div>
            </div>
        </div>
    </div>
</app-modal>
<app-modal
    [saveBtnText]="'Save'"
    *ngIf="viewResetPasswordPanel"
    [(visible)]="viewResetPasswordPanel"
    [header]="'Reset Password'"
    [context]="this"
    [saveFunc]="saveResetPassword"
    [closeFunc]="cancelResetPassword"
    [modalWidth]="'40vw'"
    [saveBtnDisabled]="!rpForm.valid || loading"
    [modalHeight]="'60vh'"
>
    <div [formGroup]="resetPasswordForm" #rpForm="ngForm">
        <div class="container-fluid">
            <div class="form-group row mt-2">
                <div class="col">
                    <input
                        [readonly]="true"
                        aria-label="Email"
                        placeholder="Email"
                        id="email"
                        type="email"
                        class="form-control"
                        formControlName="username"
                    />
                </div>
            </div>
            <div class="form-group row mt-2">
                <div class="col">
                    <input
                        aria-label="Password"
                        placeholder="Password"
                        type="password"
                        class="form-control"
                        formControlName="password"
                    />
                    <div
                        *ngIf="
                            resetPasswordForm.controls.password.errors &&
                            resetPasswordForm.controls.password.dirty
                        "
                        class="formError text-danger"
                    >
                        Password must be at least 8 characters long, and contain uppercase,
                        lowercase, special character and a number
                    </div>
                </div>
                <div class="col">
                    <input
                        aria-label="Confirm password"
                        placeholder="Confirm password"
                        type="password"
                        class="form-control"
                        autocomplete="off"
                        [value]="confirm_password"
                        formControlName="confirmPassword"
                        [class.unmatched]="resetPasswordForm.controls.confirmPassword.errors"
                        #confirm_password
                    />
                    <div
                        *ngIf="
                            resetPasswordForm.controls.confirmPassword.errors &&
                            resetPasswordForm.controls.confirmPassword.dirty
                        "
                        class="formError text-danger"
                    >
                        Does not match
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <h4 *ngIf="resetPasswordError" class="badge badge-warning text-wrap">
                        Error: {{ resetPasswordErrorMessage }}
                    </h4>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<app-modal
    [saveBtnText]="'Delete User'"
    *ngIf="deleteUserPanel"
    [(visible)]="deleteUserPanel"
    [header]="'Delete User'"
    [context]="this"
    [saveFunc]="deleteUserDialog"
    [closeFunc]="cancelDeleteDialog"
    [modalWidth]="'40vw'"
    [modalHeight]="'30vh'"
>
    <div>
        <h6>
            Are you sure you want to delete
            {{ userEditing.preferred_name }}?
        </h6>
    </div>
</app-modal>

<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body data-table-ctrl-992">
                    <div class="d-flex justify-content-between">
                        <h1>User Management</h1>
                        <div class="d-flex justify-content-end">
                            <div class="ml-1 d-flex">
                                <app-button
                                    [iconConfig]="{ iconClasses: 'fa-regular fa-sliders-v' }"
                                    [buttonClasses]="'ml-1'"
                                    style="opacity: 0.5; cursor: default; margin-bottom: 8px"
                                    (action)="showToast()"
                                >
                                </app-button>
                            </div>
                            <div *ngIf="userClaims.usersCreate" class="ml-1 d-flex">
                                <app-button
                                    [iconConfig]="{ iconClasses: 'fa-regular fa-plus' }"
                                    (action)="createUser($event)"
                                    data-target="#staticBackdrop"
                                    title="ClickToCreateNewUser"
                                >
                                </app-button>
                            </div>
                        </div>
                    </div>
                    <app-table-component
                        pagination="true"
                        [rowData]="users"
                        [columnDefs]="colDefs"
                        [filterModelKey]="'users'"
                    >
                    </app-table-component>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { SurveyService } from '@h20-services/survey.service';
import { BadgeTooltip } from '@h20-shared/button/button.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { composerUtils } from '@core/composerUtils';
import { CustomFilterComponent } from '@h20-shared/table-component/custom-filter/custom-filter.component';
import { ToastService } from '@h20-shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@h20-services/environment.service';
import { ThisReceiver } from '@angular/compiler';
@Component({
    selector: 'app-surveys',
    templateUrl: './surveys.component.html',
    styleUrls: ['./surveys.component.scss'],
})
export class SurveysComponent implements OnInit, OnDestroy {
    rates: any[];
    loading = true;
    error: any;
    userClaims = null;
    filtersOn = 0;
    tempSurveys: any[] = [];
    surveys;
    filterSurvey: any = [];
    subscription;
    showDomain = false;
    csv_data = [];
    row_ary = [];
    count = 0;
    survey: any = {};
    availableDomains = [];

    constructor(
        private svc: SurveyService,
        private fb: FormBuilder,
        private router: Router,
        private toastService: ToastService,
        private translate: TranslateService,
        private environmentSvc: EnvironmentService
    ) {}

    colDefs = [
        {
            headerName: 'title',
            field: 'title',
            flex: 2,
            cellStyle: {
                height: '100%',
                display: 'flex ',

                'align-items': 'center ',
            },
            rowHeight: 50,
            valueFormatter: (params) => this.getText(params?.value),
            filter: 'agTextColumnFilter',
            filterValueGetter: (params) => this.getText(params?.data.title),
        },
        {
            headerName: 'GUID',
            field: 'id',
            cellStyle: {
                height: '100%',
                display: 'flex ',

                'align-items': 'center ',
            },
            cellDataType: 'textWithIcon',
            cellRendererParams: (params) => {
                return {
                    iconPosition: 'after',
                    btnClass: '',
                    iconClass: 'text-muted fa-regular fa-copy clickable btn-outline-secondary',
                    iconText: params?.value?.substring(0, 8),
                    onClick: (value) => {
                        navigator.clipboard.writeText(value);
                    },
                };
            },
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Created',
            field: 'created',
            flex: 0.5,
            cellStyle: {
                height: '100%',
                display: 'flex ',

                'align-items': 'center ',
            },
            wrapText: true,
        },
        {
            headerName: 'Modified',
            field: 'modified',
            sort: 'desc',
            flex: 0.5,
            cellStyle: {
                height: '100%',
                display: 'flex ',

                'align-items': 'center ',
            },
            wrapText: true,
        },
        {
            headerName: 'Registry',          
            valueGetter: (params) => Array.isArray(params.data.registry) ? params.data.registry.join(", ") : '',
            cellStyle: {
                height: '100%',
                display: 'flex ',
                'align-items': 'center ',
            },
        },
        {
            headerName: 'action',
            field: 'id',
            flex: 2,
            cellDataType: 'iconBtns',
            autoHeight: true,
            cellStyle: {
                height: '99%',
                'padding-bottom': '5px',
                display: 'flex ',
                'align-items': 'center ',
            },
            cellRendererParams: (params) => {
                return {
                    actions: [
                        {
                            isVisible: true,
                            label: 'Edit Survey icon',
                            onClick: () => this.openSurveyBuilder(params.data),
                            iconClass: 'fas fa-pencil-alt',
                            btnClass: 'btn btn-outline-primary me-3',
                        },
                        {
                            isVisible: true,
                            label: 'Duplicate survey',
                            onClick: () => this.duplicateSurvey(params.data),
                            iconClass: 'fas fa-clone',
                            btnClass: 'btn btn-outline-secondary mx-1',
                        },
                        {
                            isVisible: true,
                            label: 'Export Data Dictionary (form definitions)',
                            onClick: () => this.downloadCSV(params.data),
                            iconClass: 'far fa-download',
                            btnClass: 'btn btn-outline-primary mx-1',
                        },
                        {
                            isVisible: true,
                            label: 'Export keys',
                            onClick: () => this.downloadKeys(params.data),
                            iconClass: 'far fa-key',
                            btnClass: 'btn btn-outline-primary mx-1',
                        },
                        {
                            isVisible: true,
                            label: 'Delete keys',
                            onClick: (event) => this.deleteSurvey(event, params.data),
                            iconClass: 'fas fa-times',
                            btnClass: 'btn btn-outline-danger mx-1',
                        },
                    ],
                };
            },
        },
    ];

    ngOnInit(): void {
        // Get all survey list
        this.getSurveys();
        this.environmentSvc.currentRegistry.subscribe((environment) => {
            this.filterByRegistry(environment);	
        });
    }

    getSurveys(): any {
        this.svc.surveys$.subscribe((result: any) => {
            this.surveys = result;           

            this.tempSurveys = [...this.surveys];

            if (!this.surveys) {
                // Nothing in the database, so use what is in local storage
                this.surveys = [];
                this.subscription = this.svc.storage.keys().subscribe((results: any) => {
                    if (results) {
                        this.svc.storage.get(results).subscribe((survey: any) => {
                            this.surveys.push(JSON.parse(survey));
                            this.surveys = [...this.surveys];
                            this.tempSurveys = [...this.surveys];
                        });
                    }
                });
            }
            this.loading = false;
        });
    }

    getSurveysByRegistry(): any {
        this.svc.getSurveysByRegistry().subscribe((result: any) => {
            this.surveys = result;

            if (!this.surveys) {
                // Nothing in the database, so use what is in local storage
                this.surveys = [];
                this.subscription = this.svc.storage.keys().subscribe((results: any) => {
                    if (results) {
                        this.svc.storage.get(results).subscribe((survey: any) => {
                            this.surveys.push(JSON.parse(survey));
                            this.surveys = [...this.surveys];
                        });
                    }
                });
            }
        });
    }

    deleteSurvey(event, survey): any {
        if (confirm(`Delete ${survey.title} ?`)) {
            const variables = { id: survey.id };
            this.svc.deleteSurvey(survey).subscribe((result: any) => {
                this.surveys = this.surveys.filter((s: any) => s.id !== survey.id);
            });
        }
    }

    onPage($event): void {}

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    updateChange(event, row): void {
        row.domain = event.target.value;

        // Don't update the json - only the attribute - we won't track in json anymore

        // var parsedJson = JSON.parse(row.json);
        // parsedJson.domain = event.target.value;
        // row.json = JSON.stringify(parsedJson);
        this.svc.updateSurvey(row).then((resp: any) => {
            resp.subscribe((result: any) => {});
        });
    }


    getText(strOrLangs): string {
        if (!strOrLangs) return '';
        return strOrLangs['en'] || strOrLangs['default'] || strOrLangs;
    }

    downloadCSV(row): any {
        this.svc.getSurveyById(row.id).subscribe((dt) => {
            let file_name = this.getText(dt[0].title);
            let export_data = '';
            let csv_headers = [
                'Section Name',
                'Description',
                'Mini-Form Name',
                'Name',
                'Logic',
                'Required',
                'Questions',
                'Input Type',
                'Input Subtype',
                'Response options',
                'Response Value',
                'isExclusive',
                'Validators',
                'RequiredErrorText',
                'MinMaxDescription',
            ];
            JSON.parse(dt[0].json).pages[0].elements.forEach((row) => {
                export_data += this.dataToCSV(row) + '\n';
            });
            this.downloadFile(file_name, csv_headers.join(',') + '\n' + export_data);
        });
    }

    openSurveyBuilder(row): any {
        this.router.navigate(['surveys/build/' + row.id + '/' + row.domain]);
    }

    processVisibleIf(
        sectionName,
        description,
        miniformName,
        visibleIf,
        title,
        value,
        text,
        type,
        subType,
        isRequired,
        name,
        validators,
        requiredErrorText,
        minDescription,
        maxDescription,
        isExclusive
    ) {
        this.row_ary = [];
        this.row_ary.push(sectionName);
        this.row_ary.push(description);
        this.row_ary.push(miniformName); // Miniform-name
        this.row_ary.push(name);
        this.row_ary.push(visibleIf ? visibleIf : '');
        this.row_ary.push(isRequired === '' ? '' : isRequired ? 'Yes' : 'No');
        this.row_ary.push(this.getText(title).replace(/[,]/g, ';'));
        this.row_ary.push(type);
        this.row_ary.push(subType);
        this.row_ary.push(this.getText(text).replace(/[,]/g, ';'));
        this.row_ary.push(value);
        if (isExclusive) {
            this.row_ary.push(isExclusive);
        } else this.row_ary.push('');
        if (validators && validators[0]) {
            if (validators[0].type == 'numeric') {
                this.row_ary.push(
                    `minValue: ${validators[0].minValue ? validators[0].minValue : ''}; maxValue: ${
                        validators[0].maxValue ? validators[0].maxValue : ''
                    }`
                );
            } else if (validators[0].type == 'regex') {
                this.row_ary.push(validators[0].text);
            } else if (validators[0].type == 'expression') {
                this.row_ary.push(validators[0].text ? this.getDefault(validators[0].text) : '');
            } else this.row_ary.push('');
        } else this.row_ary.push('');
        this.row_ary.push(requiredErrorText);

        if (minDescription || maxDescription) {
            this.row_ary.push(
                `minDescription: ${
                    minDescription ? this.getDefault(minDescription) : minDescription == 0 ? 0 : ''
                }; maxDescription: ${
                    maxDescription ? this.getDefault(maxDescription) : maxDescription == 0 ? 0 : ''
                }`
            );
        } else this.row_ary.push('');

        this.csv_data.push(this.row_ary.join(','));
    }
    processChoices(row) {
        if (row.type === 'html') {
            this.row_ary = [];
            this.processVisibleIf(
                '',
                this.getText(row.html).replace(/[,]/g, ';'),
                '',
                row.visibleIf,
                '',
                '',
                '',
                '',
                '',
                '',
                this.getText(row.name),
                '',
                '',
                '',
                '',
                ''
            );
        } else if (row.choices) {
            for (var i = 0; i < row.choices.length; i++) {
                this.row_ary = [];
                if (i == 0) {
                    this.processVisibleIf(
                        '',
                        '',
                        '',
                        row.visibleIf,
                        row.title,
                        row.choices[i].value,
                        row.choices[i].text,
                        row.type,
                        row.inputType,
                        row.isRequired,
                        row.name,
                        row.validators,
                        row.requiredErrorText,
                        row.minRateDescription ? row.minRateDescription : row.min,
                        row.maxRateDescription ? row.maxRateDescription : row.max,
                        row.choices[i].isExclusive
                    );
                } else {
                    this.processVisibleIf(
                        '',
                        '',
                        '',
                        false,
                        '',
                        row.choices[i].value,
                        row.choices[i].text,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        row.choices[i].isExclusive
                    );
                }
            }
        } else if (row.templateElements) {
            this.processVisibleIf(
                '',
                '',
                '[START]' + this.getText(row.title).replace(/[,]/g, ';'),
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            );
            this.processVisibleIf(
                '',
                '',
                '',
                row.visibleIf,
                row.title,
                '',
                '',
                row.type,
                row.inputType,
                row.isRequired,
                row.name,
                row.validators,
                row.requiredErrorText ? this.getDefault(row.requiredErrorText) : '',
                row.minRateDescription ? row.minRateDescription : row.min,
                row.maxRateDescription ? row.maxRateDescription : row.max,
                row.isExclusive
            );
            for (var i = 0; i < row.templateElements.length; i++) {
                this.processChoices(row.templateElements[i]);
            }
            this.processVisibleIf(
                '',
                '',
                '[END]' + this.getText(row.title).replace(/[,]/g, ';'),
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            );
        } else {
            this.row_ary = [];
            this.processVisibleIf(
                '',
                '',
                '',
                row.visibleIf,
                row.title,
                '',
                '',
                row.type,
                row.inputType,
                row.isRequired,
                row.name,
                row.validators,
                row.requiredErrorText ? this.getDefault(row.requiredErrorText) : '',
                row.minRateDescription ? row.minRateDescription : row.min,
                row.maxRateDescription ? row.maxRateDescription : row.max,
                row.isExclusive
            );
        }
    }

    dataToCSV(data): any {
        this.csv_data = [];
        this.processVisibleIf(
            data.name,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        );
        data.elements.forEach((row) => {
            this.processChoices(row);
        });
        return this.csv_data.join('\n');
    }
    downloadFile(file_name, export_data): void {
        let blob = new Blob([export_data], { type: 'text/csv' });
        saveAs(blob, file_name + '.csv');
    }

    getDefault(data) {
        return data['default'] ? data['default'] : data['en'] ? data['en'] : data;
    }

    duplicateSurvey(row): any {
        this.svc.getSurveyById(row.id).subscribe((dt) => {
            var tempSurvey = JSON.parse(dt[0].json);
            var duplicateSurvey: any = {};
            duplicateSurvey.domain = tempSurvey.domain;
            duplicateSurvey.pages = tempSurvey.pages;
            if (tempSurvey.title.default) {
                duplicateSurvey.title = tempSurvey.title;
                duplicateSurvey.title.default = tempSurvey.title.default + '_copy';
            } else duplicateSurvey.title = tempSurvey.title + '_copy';

            this.svc.saveSurvey(duplicateSurvey).then((res: any) => {
                res.subscribe((result: any) => {
                    if (result[0]) {
                        this.survey = duplicateSurvey;
                        this.survey.id = result[0].id;
                        this.svc.surveys$ = this.svc.getSurveys();
                        //save S3
                        this.svc.saveSurvey_Builder(this.survey).then((res: any) => {
                            res.subscribe((result: any) => {
                                this.loading = true;
                                this.getSurveys();
                            });
                        });
                    }
                });
            }),
                (error: Error) => {
                    alert(error.message);
                };
        });
    }

    downloadKeys(row): any {
        this.svc.getSurveyById(row.id).subscribe((dt) => {
            let file_name = this.getText(dt[0].title);
            let export_data = '';
            const header_ary = [
                'survey_id',
                'question_key',
                'parent_question_key',
                'question_type',
                'handler',
                'dest_table',
                'dest_column',
                'registry_id',
            ];
            export_data += header_ary.join(',') + '\n';
            JSON.parse(dt[0].json).pages[0].elements.forEach((el) => {
                export_data += this.dataKeys(el, row.id) + '\n';
            });
            this.downloadFile(file_name, '' + export_data);
        });
    }

    dataKeys(data, survey_id): any {
        this.csv_data = [];
        data.elements.forEach((row) => {
            this.processChoicesKeys(row, survey_id, 'null');
        });
        return this.csv_data.join('\n');
    }

    processChoicesKeys(row, survey_id, parent_key) {
        if (row.type == 'paneldynamic') {
            row.templateElements.forEach((el) => {
                this.processChoicesKeys(el, survey_id, row.name);
            });
        }
        if (row.type !== 'html') {
            this.count += 1;
            this.row_ary = [];
            this.row_ary.push(survey_id); //A
            this.row_ary.push(row.name); //B
            this.row_ary.push(parent_key); //C
            this.row_ary.push(row.type); //D
            this.row_ary.push('default'); //E
            this.row_ary.push('null'); //F
            this.row_ary.push('null'); //G
            this.row_ary.push('null'); //H

            this.row_ary.push(
                `"=CONCATENATE(""insert into question_key_mapping (id, question_key, parent_question_key, registry_ID, survey_ID, type, handler, dest_table, dest_column, lookup_table) values (uuid(),\'"",B${this.count},""\',"",IF(C${this.count}=""null"", ""null"",CONCATENATE(""\'"",C${this.count},""\'"")), "","",IF(H${this.count}=""null"", ""null"",CONCATENATE(""\'"",H${this.count},""\'"")), "",\'"",A${this.count},""\',\'"",D${this.count},""\',\'"",E${this.count},""\',"",IF(F${this.count}=""null"", ""null"",CONCATENATE(""\'"",F${this.count},""\'"")), "","",IF(G${this.count}=""null"", ""null"",CONCATENATE(""\'"",G${this.count},""\'"")),"",NULL);"")"`
            );
            this.csv_data.push(this.row_ary.join(','));
        }
    }

    showToast() {
        this.toastService.show({
            body: this.translate.instant('Toast.FilterOptions'),
        });
    }

    filterByRegistry(registry: string){
        this.surveys = this.tempSurveys.filter((survey: any) => {
            if(Array.isArray(survey.registry)){
                if(survey.registry.includes(registry)){
                    return survey;
                }                
            } 
        });

        if(registry === "All"){
            this.surveys = this.tempSurveys;
        }
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-pi-form-view-response',
    templateUrl: './pi-form-view-response.component.html',
    styleUrls: ['./pi-form-view-response.component.scss'],
})
export class PiFormViewResponseComponent {
    @Input() element: any;
    @Input() theFormControl: UntypedFormControl;
    @Input() displayValue: string;
    @Input() download: string;
    @Input() isRestrictedDownload: boolean;
    constructor(protected translate: TranslateService) {}

    
    @Output() downloadButtonClicked: EventEmitter<string> = new EventEmitter<string>();

    downloadClicked(item: any) {
        this.downloadButtonClicked.emit(item);
    }
}

<div class="toast-container">
  <ngb-toast *ngFor="let toast of toasts$ | async" [class]="toast.type">
    <div class="toast-content">
      <div class="toast-icon-wrapper" *ngIf="toast.icon">
        <app-featured-icon
          class="col-24"
          [iconName]="toast.icon"
          [size]="'small'"
          [type]="toast.type"
          [rounded]="true"
          [customStyle]="{ 'margin-top': '10px' }"
        ></app-featured-icon>
      </div>
      <div class="toast-body">
        <strong>{{ toast.heading }}</strong>
        <div>{{ toast.body }}</div>
        <div *ngIf="toast.buttons && toast.buttons.length > 0">
          <button
            class="toast-actions"
            *ngFor="let button of toast.buttons"
            (click)="button.action()"
          >
            {{ button.text }}
          </button>
        </div>
      </div>
      <app-featured-icon
        class="col-24"
        [iconName]="'fa-regular fa-circle-xmark'"
        [size]="'small'"
        [customStyle]="{ opacity: '0.3', cursor: 'pointer' }"
        (click)="remove(toast)"
      ></app-featured-icon>
    </div>
  </ngb-toast>
</div>

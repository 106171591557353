<div class="d-flex w-100">
    <div class="element-list" [ngClass]="navExpanded ? 'element-nav-open' : 'element-nav-closed'">
        <div class="d-flex justify-content-end mb-2 w-100">
            <ul class="nav flex-column nav-pills nav-justified w-100" id="element-nav-list">
                <li
                    *ngFor="let element of elements; let i = index"
                    class="nav-item d-flex w-100 align-items-center border border-1"
                >
                    <div class="d-flex flex-column h-100 justify-content-between">
                        <button
                            class="mini-button btn-top mx-1"
                            (click)="moveUp(i)"
                            [disabled]="i === 0"
                        >
                            <div class="fas fa-arrow-up"></div>
                        </button>
                        <button
                            class="mini-button btn-bottom mx-1"
                            (click)="moveDown(i)"
                            [disabled]="i === elements.length - 1"
                        >
                            <div class="fas fa-arrow-down"></div>
                        </button>
                    </div>
                    <a
                        href="{{ '#nav-' + element.name }}"
                        aria-controls="{{ '#nav-' + element.name }}"
                        class="nav-link d-flex align-items-center"
                        [ngClass]="i === 0 ? 'active' : ''"
                        id="{{ 'nav-' + element.name + '-tab' }}"
                        data-bs-toggle="tab"
                        role="tab"
                    >
                        <span class="fa-stack tiny-text mx-1">
                            <i class="fa-solid fa-square fa-stack-2x" [ngClass]="getColor(i)"></i>
                            <i class="fa-solid fa-stack-1x fa-inverse" [ngClass]="getIcon(i)"></i>
                        </span>
                        <div class="font-monospace me-3">#{{ i + 1 }}</div>
                        <span *ngIf="navExpanded" class="text-wrap w-100 text-start font-size-12">{{
                            getText(element.title)
                        }}</span>
                    </a>
                    <div class="d-flex flex-column h-100 justify-content-between">
                        <button class="mini-button btn-top mx-1" (click)="addBefore(i)">
                            <i class="fas fa-plus"></i>
                            <i class="fas fa-arrow-turn-up"></i>
                        </button>
                        <button class="mini-button btn-bottom mx-1" (click)="addAfter(i)">
                            <i class="fas fa-plus"></i>
                            <i class="fas fa-arrow-turn-down"></i>
                        </button>
                    </div>
                </li>
                <button class="btn btn-light" (click)="addAfter(elements.length)">
                    <i class="fas fa-plus"></i>
                </button>
            </ul>
        </div>
    </div>
    <button class="mini-button" (click)="toggleNav()">
        <i class="fas" [ngClass]="navExpanded ? 'fa-caret-left' : 'fa-caret-right'"></i>
    </button>
    <div tabindex="0" class="tab-content flex-grow-1 ms-3">
        <ng-container *ngFor="let element of elements; let i = index">
            <div
                [ngClass]="i === 0 ? 'active show' : ''"
                id="{{ 'nav-' + element.name }}"
                role="tabpanel"
                aria-labelledby="{{ '#nav-' + element.name + '-tab' }}"
                class="tab-pane border border-2 rounded mb-3"
            >
                <div class="element-header p-3 d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="font-monospace text-muted me-3">#{{ i + 1 }}</div>
                        <div class="font-monospace text-muted ms-5">
                            {{ getText(element.name) }}
                        </div>
                        <div class="ms-1">: {{ getText(element.title) }}</div>
                    </div>
                    <div class="d-flex">
                        <span class="fa-stack tiny-text mx-1">
                            <i class="fa-solid fa-square fa-stack-2x" [ngClass]="getColor(i)"></i>
                            <i class="fa-solid fa-stack-1x fa-inverse" [ngClass]="getIcon(i)"></i>
                        </span>
                        <div class="me-2">{{ getTypeText(element) }}</div>
                        <button class="btn btn-outline-primary tiny-text" (click)="deleteAt(i)">
                            <i class="fas fa-trash-can"></i>
                        </button>
                    </div>
                </div>
                <div class="element-body p-3">
                    <app-element-designer
                        *ngIf="elements[i]"
                        [index]="i"
                        [(elements)]="elements"
                        (eventChange)="eventElementDesignerChange($event)"
                    >
                    </app-element-designer>
                </div>
                <div class="element-footer d-flex justify-content-between">
                    <div class="d-flex">
                        <div class="font-monospace text-muted">{{ getText(element.name) }}</div>
                        <div class="ms-1">: {{ getText(element.title) }}</div>
                    </div>
                    <div class="d-flex">
                        <div class="me-2">{{ getTypeText(element) }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<app-modal
    *ngIf="adding"
    [(visible)]="adding"
    header="Add Element"
    [context]="this"
    [closeFunc]="clearAdding"
>
    <h3>Search the library</h3>
    <app-fragment-search (addElementEvent)="addPrefab($event)"></app-fragment-search>
    <hr />
    <h3>Create a new question</h3>
    <div class="row">
        <div *ngFor="let element of ELEMENT_TYPES" class="col-3 my-1">
            <div
                class="p-4 w-100 shadow-sm border border-1 rounded d-flex align-items-center justify-content-start"
                (click)="confirmAdd(element.text)"
            >
                <div class="fa-stack fs-4">
                    <i class="fa-solid fa-square fa-stack-2x" [ngClass]="element.color"></i>
                    <i class="fa-solid fa-stack-1x fa-inverse" [ngClass]="element.icon"></i>
                </div>
                <div>{{ getText(element.displayText) }}</div>
            </div>
        </div>
    </div>
</app-modal>

const lambdaEndpoint = 'https://muixpj962i.execute-api.us-east-1.amazonaws.com/Prod/';

export const environment = {
    production: false,
    development: true,
    captchaOn: false,
    awsConfig: {
        Auth: {
            identityPoolId: 'us-east-1:73b0780c-a242-4c2c-8860-940bc66c4bcc',
            region: 'us-east-1',
            userPoolId: 'us-east-1_JMJDfKzME',
            userPoolWebClientId: '5cucug6g4nhmad1hfi0qj7s1kk',
            mandatorySignIn: false,
            cookieStorage: {
                secure: true,
            },
            storage: sessionStorage,
            authenticationFlowType: 'USER_SRP_AUTH',
            oauth: {
                domain: 'nkfpatientnetwork.h20-dev.healthie.net/login',
                scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: 'https://nkfpatientnetwork.h20-dev.healthie.net/',
                redirectSignOut: 'https://nkfpatientnetwork.h20-dev.healthie.net/logout/',
                responseType: 'code',
            },
        },
        API: {
            aws_web_assets: 'h20-development-web-assets.s3.amazonaws.com',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'application/json',
                Accept: 'application/json, text/plain',
            },
            createH20Account: 'NA',
            getConsentDoc:
                'https://r3c6vaqw76.execute-api.us-east-1.amazonaws.com/default/GetConsentDoc',
            writeToBucketURL: lambdaEndpoint + 'WriteToBucket',
            writeToBucketKey: 'NA',
            readFromBucketURL: lambdaEndpoint + 'ReadFromBucket',
            readFromBucketKey: 'rvb5XIbgd62qe5P9I2PF62Lhgh3OZCod1AHfIg4G',
            listBucketItemsURL: lambdaEndpoint + 'ListBucketItems',
            listBucketItemsKey: 'Lo9798jpdI5r4l6OUSMdq6BA3eHFKVsQaiZ20C6b',
            setExternalIdentifierURL: lambdaEndpoint + 'SetExternalIdentifier',
            writeToDb: 'NA',
            writeToDbKey: 'NA',
            userServiceProvider: lambdaEndpoint + 'UserServiceProvider',
            writeToDBUser: lambdaEndpoint + 'WriteToDBUser',
            writeToDBUserAttribute: lambdaEndpoint + 'WriteToDBUserAttribute',
            writeToDBSurvey: lambdaEndpoint + 'WriteToDBSurvey',
            writeToDBConsent: lambdaEndpoint + 'WriteToDBConsent',
            writeToDBActivationStatus: lambdaEndpoint + 'WriteToDBActivationStatus',
            writeToDBTask: lambdaEndpoint + 'WriteToDBTask',
            writeToDBPatient: lambdaEndpoint + 'WriteToDBPatient',
            writeToDBInfo: lambdaEndpoint + 'WriteToDBInfo',
            sendEmailFailedLogin: 'NA',
            writeToDBSite: lambdaEndpoint + 'WriteToDBSite',
            writeToDBClaim: lambdaEndpoint + 'WriteToDBClaim',
            sendAdminNotification: 'NA',
            getExportData: lambdaEndpoint + 'GetExportData',
            getDataExportList: lambdaEndpoint + 'GetDataExportList',
            registerUser: 'NA',
            userSettingsService: lambdaEndpoint + 'UserSettingsService',
            writeToDBCaseReview: lambdaEndpoint + 'WriteToDBCaseReview',
            scoringSubmission: 'NA',
            writeFileToBucket: lambdaEndpoint + 'WriteFileToBucket',
            submissionTriggers: lambdaEndpoint + 'SubmissionTriggers',
            hardDeletePatient: lambdaEndpoint + 'HardDeletePatient',
            writeToDBClinicalData: lambdaEndpoint + 'WriteToDBClinicalData',
            getToken: lambdaEndpoint + 'GetToken',
            sendEmailTemplate: lambdaEndpoint + 'SendEmailTemplate',
            updateParticipantStatus: 'NA',
            writeToDBLog: lambdaEndpoint + 'WriteToDBLog',
            writeToDBTrackers: lambdaEndpoint + 'WriteToDBTrackers',
            docusignHandler: lambdaEndpoint + 'DocusignHandler',
            DDBEmailTemplates: lambdaEndpoint + 'DDBEmailTemplates',
            registryConfiguration: lambdaEndpoint + 'RegistryConfiguration',
            writeToDBRegistry: lambdaEndpoint + 'WriteToDBRegistry',
            emailMapping: lambdaEndpoint + 'EmailMapping',
            scheduleConfiguration: lambdaEndpoint + 'ScheduleConfiguration',
            deployManagement: lambdaEndpoint + 'DeployManagement',
            writeToDBDashboard: 'NA',
            anonymousSurveyHandler: lambdaEndpoint + 'AnonymousSurveyHandler',
            getWithdrawnData: lambdaEndpoint + 'GetWithdrawnData',
            layoutConfiguration: lambdaEndpoint + 'LayoutConfiguration',
            clinicalScreenerHandler: lambdaEndpoint + 'ClinicalScreenerHandler',
            choicesFromURLWriter: lambdaEndpoint + 'ChoicesFromURLWriter',
            choicesFromURLHandler: lambdaEndpoint + 'ChoicesFromURLHandler',
            dashboardConfiguration: lambdaEndpoint + 'DashboardConfiguration',
            surveySessionHandler: 'NA',
            greenlightHandler: 'NA',
            sendAdminNotificationPrtRegister: 'NA',
            listBucketItemsComposer: lambdaEndpoint + 'ListBucketItemsComposer',
            readFromBucketComposer: lambdaEndpoint + 'ReadFromBucketComposer',
            vocabularyConfiguration: lambdaEndpoint + 'VocabularyConfiguration',
            surveyQuestionKeyMappingHandler: lambdaEndpoint + 'SurveyQuestionKeyMappingHandler',
            surveySubmissionHandler: lambdaEndpoint + 'SurveySubmissionHandler',
            getSurveySubmission: lambdaEndpoint + 'GetSurveySubmission',
        },
        Storage: {
            AWSS3: {
                deployed_ui_bucket: 'h20-development-ui-deployment',
                bucket: 'h20-development-data',
                region: 'us-east-1',
            },
        },
        Path: {
            defaultWebAssetPath: 'https://h20-development-web-assets.s3.amazonaws.com',
            liveWebAssetPath: 'https://h20-live-web-assets-ca-central-1.s3.amazonaws.com',
        },
    },
};

import { Component, Injector, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { SurveyService } from '@h20-services/survey.service';
import { RequestService } from '@h20-services/request.service';
import { ClauseLocation, LogicFormTree, LogicNodeType } from './models/LogicFormTree';
import { UuidService } from '@h20-services/uuid.service';

@Component({
    selector: 'app-pi-control-logic-builder',
    templateUrl: './pi-control-logic-builder.component.html',
    styleUrls: ['./pi-control-logic-builder.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlLogicBuilderComponent,
        },
    ],
})
export class PiControlLogicBuilderComponent
    extends AbstractPiControlComponent<IFormElement, any>
    implements OnInit, OnChanges
{
    loading = false;

    currentChoice: any;
    logicTree: any;
    logicForm: FormGroup;
    maxId: number = 0; //current maximum id

    editingModal: boolean = false;

    compareOperatorFormElement = {
        type: 'droptext',
        title: 'Operator',
        titleLocation: 'hidden',
        orientation: 'vertical',
        choices: [
            { value: 1, text: '=' },
            { value: 2, text: '<>' },
            { value: 3, text: '>' },
            { value: 4, text: '<' },
            { value: 5, text: '>=' },
            { value: 6, text: '<=' },
        ],
    };

    logicOperatorFormElement = {
        type: 'droptext',
        title: 'Operator',
        orientation: 'vertical',
        titleLocation: 'hidden',
        choices: [
            { value: 1, text: 'and' },
            { value: 2, text: 'or' },
        ],
    };

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        private req_svc: RequestService,
        protected translate: TranslateService,
        private survey_svc: SurveyService,
        private fb: FormBuilder
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges() {
        if (this.logicTree) this.logicTree.parentList = this.parentList;
    }

    onEdit() {
        this.editingModal = true;
        //reinitialize the tree and form
        this.parseTreeString(this._value);
    }

    onSave(ctx) {
        if (ctx.isValid()) {
            ctx.update();
            ctx.editingModal = false;
            return true;
        }
        return false;
    }
    onClose() {}

    update() {
        super.writeValue(this.logicTree.toString());
        this.notifyValueChange();
    }

    writeValue(value: any) {
        super.writeValue(value);
        //set the initial value
        this.parseTreeString(value);
    }

    parseTreeString(treeString: string) {
        this.logicTree = new LogicFormTree(treeString, this.parentList);
        this.logicForm = this.logicTree.logicForm;
    }

    isValid(): boolean {
        return this.logicTree.isValid();
    }

    //make logic tree enum available to the template
    public get ClauseLocation(): typeof ClauseLocation {
        return ClauseLocation;
    }

    public get LogicNodeType(): typeof LogicNodeType {
        return LogicNodeType;
    }

    /**
     * Returns whether the term matches the item.
     * Used by the ng-select to customize how it
     * searches all items in the list to identify matches.
     * @param term the search term
     * @param item the element to check
     * @returns true if there is a match and false otherwise
     */
    elementSearchFn(term: string, item: IFormElement) {
        term = term.toLowerCase();

        let compareTitle = '';
        if (item.title['default']) compareTitle = item.title['default'];
        else if (item.title['en']) compareTitle = item.title['en'];
        else compareTitle += item.title;

        return (
            item.name.toLowerCase().indexOf(term) > -1 ||
            compareTitle.toLowerCase().indexOf(term) > -1
        );
    }
}

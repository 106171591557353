<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <h1 class="mb-0 mr-3">Registration Content:</h1>
                        <div *ngIf="!selectedRegistry" class="formError text-danger mx-3">
                            Please select a registry
                        </div>
                        <app-button
                            *ngIf="isInit" 
                            class="mx-3"
                            (action)="saveRegistrationContent()"
                            [buttonText]="'Registry.Save'"
                        />
                    </div>
					<app-loading-component [loading]="saving"></app-loading-component>
                    <hr />

                    <app-loading-component [loading]="loading">
                        <div
                            *ngIf="isInit"
                            class="container-fluid right-panel-active reg-page"
                        >
                            <div class="row">
                                <!-- Left Panel - Start -------------------------------------------- -->
                                <div
                                    class="card col-12 col-md-8 col-xl-9 pt-4 px-cus"
                                >
                                    <h1 *ngIf="mainTitle[defaultLang]">
                                        {{ mainTitle[defaultLang] }}
                                    </h1>
                                    <textarea
                                        class="form-control"
                                        placeholder="mainTitle"
                                        [value]="mainTitle[defaultLang]"
                                        (change)="
                                            onTextChange(
                                                mainTitle,
                                                defaultLang,
                                                $event
                                            )
                                        "
                                    ></textarea>

                                    <p
                                        *ngIf="mainSubtitle[defaultLang]"
                                        class="sub-txt"
                                    >
                                        {{ mainSubtitle[defaultLang] }}
                                    </p>
                                    <textarea
                                        class="form-control"
                                        placeholder="mainSubtitle"
                                        [value]="mainSubtitle[defaultLang]"
                                        (change)="
                                            onTextChange(
                                                mainSubtitle,
                                                defaultLang,
                                                $event
                                            )
                                        "
                                    ></textarea>

                                    <p *ngIf="mainFinePrint[defaultLang]">
                                        {{ mainFinePrint[defaultLang] }}
                                    </p>
                                    <textarea
                                        class="form-control"
                                        placeholder="mainFinePrint"
                                        [value]="mainFinePrint[defaultLang]"
                                        (change)="
                                            onTextChange(
                                                mainFinePrint,
                                                defaultLang,
                                                $event
                                            )
                                        "
                                    ></textarea>

                                    <div
                                        class="card reg-panel-form mb-4 mb-md-0"
                                    >
                                        <div
                                            class="card-body p-2 p-sm-3 p-lg-5"
                                        >
                                            <div
                                                class="card-body-props offset-0 offset-lg-2 offset-xl-4 col-auto p-4"
                                            >
                                                <h3 *ngIf="registerTitle[defaultLang]">
                                                    {{ registerTitle[defaultLang] }}
                                                </h3>
                                                <textarea
                                                    class="form-control"
                                                    placeholder="registerTitle"
                                                    [value]="registerTitle[defaultLang]"
                                                    (change)="
                                                        onTextChange(
                                                            registerTitle,
                                                            defaultLang,
                                                            $event
                                                        )
                                                    "
                                                ></textarea>
                                                <p *ngIf="registerSubtitle[defaultLang]">
                                                    {{ registerSubtitle[defaultLang] }}
                                                </p>
                                                <textarea
                                                    class="form-control"
                                                    placeholder="registerSubtitle"
                                                    [value]="registerSubtitle[defaultLang]"
                                                    (change)="
                                                        onTextChange(
                                                            registerSubtitle,
                                                            defaultLang,
                                                            $event
                                                        )
                                                    "
                                                ></textarea>
                                                <div class="msg-welcome">
                                                    <div
                                                        class="card reg-panel-form mb-4 mb-md-0"
                                                    >
                                                        <div
                                                            class="card-body p-2 p-sm-3 p-lg-5"
                                                        >
                                                            <div
                                                                class="card-body-props offset-0 offset-lg-2 offset-xl-4 col-auto p-4"
                                                            >
                                                                Doom here
                                                                <label
                                                                    >Registration
                                                                    Survey:
                                                                    <select
                                                                        class="form-control"
                                                                        (change)="
                                                                            surveyChange(
                                                                                $event
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        "
                                                                    >
                                                                        <option
                                                                            selected
                                                                            disabled
                                                                        >
                                                                            none
                                                                        </option>
                                                                        <option
                                                                            *ngFor="
                                                                                let survey of listSurveys
                                                                            "
                                                                            [selected]="
                                                                                survey.id ===
                                                                                selectedSurvey
                                                                            "
                                                                            [ngValue]="
                                                                                survey
                                                                            "
                                                                            [value]="
                                                                                survey.id
                                                                            "
                                                                        >
                                                                            {{
                                                                                survey.name
                                                                            }}
                                                                        </option>
                                                                    </select></label
                                                                >
                                                                <label
                                                                    >Register
                                                                    Participant
                                                                    Survey:
                                                                    <select
                                                                        class="form-control"
                                                                        (change)="
                                                                            participantSurveyChange(
                                                                                $event
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        "
                                                                    >
                                                                        <option
                                                                            selected
                                                                            disabled
                                                                        >
                                                                            none
                                                                        </option>
                                                                        <option
                                                                            *ngFor="
                                                                                let survey of listSurveys
                                                                            "
                                                                            [selected]="
                                                                                survey.id ===
                                                                                selectedParticipantSurvey
                                                                            "
                                                                            [ngValue]="
                                                                                survey
                                                                            "
                                                                            [value]="
                                                                                survey.id
                                                                            "
                                                                        >
                                                                            {{
                                                                                survey.name
                                                                            }}
                                                                        </option>
                                                                    </select></label
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p *ngIf="registerDisclaimer[defaultLang]" [innerHTML]="registerDisclaimer[defaultLang]">
                                    </p>
                                    <textarea
                                        class="form-control"
                                        placeholder="registerDisclaimer"
                                        [value]="registerDisclaimer[defaultLang]"
                                        (change)="
                                            onTextChange(
                                                registerDisclaimer,
                                                defaultLang,
                                                $event
                                            )
                                        "
                                    ></textarea>
                                    <br />
                                    <hr />
                                    <br />

                                    <div
                                        class="card reg-panel-form mb-4 mb-md-0"
                                    >
                                        <div
                                            class="card-body p-2 p-sm-3 p-lg-5"
                                        >
                                            <div
                                                class="card-body-props offset-0 offset-lg-2 offset-xl-4 col-auto p-4"
                                            >
                                                <div class="msg-welcome">
                                                    <div class="text-center">
                                                        <i
                                                            class="fal fa-paper-plane fa-10x color-client-accent-2"
                                                        ></i>
                                                    </div>
                                                    <div
                                                        class="text-center mb-3"
                                                    >
                                                        <h2>
                                                            'Registry.Success' |
                                                            translate }}
                                                        </h2>
                                                    </div>
                                                    <h1 *ngIf="thankYouTitle">
                                                        {{
                                                            thankYouTitle[
                                                                defaultLang
                                                            ]
                                                        }}
                                                    </h1>
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="thankYouTitle"
                                                        [value]="
                                                            thankYouTitle[
                                                                defaultLang
                                                            ]
                                                        "
                                                        (change)="
                                                            onTextChange(
                                                                thankYouTitle,
                                                                defaultLang,
                                                                $event
                                                            )
                                                        "
                                                    ></textarea>

                                                    <p
                                                        *ngIf="thankYouSubtitle"
                                                        class="font-size-18"
                                                    >
                                                        {{
                                                            thankYouSubtitle[
                                                                defaultLang
                                                            ]
                                                        }}
                                                    </p>
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="thankYouSubtitle"
                                                        [value]="
                                                            thankYouSubtitle[
                                                                defaultLang
                                                            ]
                                                        "
                                                        (change)="
                                                            onTextChange(
                                                                thankYouSubtitle,
                                                                defaultLang,
                                                                $event
                                                            )
                                                        "
                                                    ></textarea>

                                                    <p
                                                        *ngIf="
                                                            thankYouNextSteps
                                                        "
                                                        class="font-size-18"
                                                    >
                                                        {{
                                                            thankYouNextSteps[
                                                                defaultLang
                                                            ]
                                                        }}
                                                    </p>
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="thankYouNextSteps"
                                                        [value]="
                                                            thankYouNextSteps[
                                                                defaultLang
                                                            ]
                                                        "
                                                        (change)="
                                                            onTextChange(
                                                                thankYouNextSteps,
                                                                defaultLang,
                                                                $event
                                                            )
                                                        "
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Left Panel - End -------------------------------------------- -->

                                <!-- Right Panel - Start -------------------------------------------- -->
                                <div
                                    class="col-12 col-md-4 col-xl-3 py-4 mt-0 pt-0 px-cus bg-white"
                                >
                                    <div class="row right-panel">
                                        <div
                                            *ngFor="let section of infoSections"
                                        >
                                            <div class="card px-2 py-2">
                                                <div class="col-12 mb-3">
                                                    <h2
                                                        *ngIf="
                                                            section?.title[
                                                                defaultLang
                                                            ]
                                                        "
                                                    >
                                                        {{
                                                            section.title[
                                                                defaultLang
                                                            ]
                                                        }}
                                                    </h2>
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="section.title"
                                                        [value]="
                                                            section.title[
                                                                defaultLang
                                                            ]
                                                        "
                                                        (change)="
                                                            onTextChange(
                                                                section.title,
                                                                defaultLang,
                                                                $event
                                                            )
                                                        "
                                                    ></textarea>

                                                    <h5
                                                        *ngIf="
                                                            section?.subtitle[
                                                                defaultLang
                                                            ]
                                                        "
                                                    >
                                                        {{
                                                            section.subtitle[
                                                                defaultLang
                                                            ]
                                                        }}
                                                    </h5>
                                                    <textarea
                                                        class="form-control"
                                                        placeholder="section.subtitle"
                                                        [value]="
                                                            section.subtitle[
                                                                defaultLang
                                                            ]
                                                        "
                                                        (change)="
                                                            onTextChange(
                                                                section.subtitle,
                                                                defaultLang,
                                                                $event
                                                            )
                                                        "
                                                    ></textarea>
                                                </div>
                                                <div
                                                    *ngIf="section?.video"
                                                    class="col-12 vid-box"
                                                >
                                                    <div>
                                                        <iframe
                                                            [src]="videoUrl"
                                                            title="Video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        >
                                                        </iframe>
                                                    </div>
                                                </div>
                                                <input
                                                    *ngIf="
                                                        infoSections.indexOf(
                                                            section
                                                        ) === 0
                                                    "
                                                    class="form-control"
                                                    placeholder="section.videoURL"
                                                    [value]="section.video"
                                                    (change)="
                                                        onTextChange(
                                                            section,
                                                            'video',
                                                            $event
                                                        )
                                                    "
                                                />

                                                <div
                                                    *ngFor="
                                                        let e of section.elements
                                                    "
                                                    class="col-12 mb-3"
                                                >
                                                    <div class="row pl-3">
                                                        <div class="d-table">
                                                            <div>
                                                                <div
                                                                    *ngIf="
                                                                        e.icon
                                                                    "
                                                                    class="d-table-cell pr-3"
                                                                >
                                                                    <div
                                                                        class="badge badge-primary d-flex align-items-center justify-content-center"
                                                                    >
                                                                        <i
                                                                            [class]="
                                                                                e.icon ||
                                                                                'fas fa-school'
                                                                            "
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    class="form-control"
                                                                    placeholder="icon"
                                                                    [value]="
                                                                        e.icon
                                                                    "
                                                                    (change)="
                                                                        onTextChange(
                                                                            e,
                                                                            'icon',
                                                                            $event
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    [ngClass]="
                                                                        e.icon
                                                                            ? 'd-table-cell pl-5'
                                                                            : 'd-table-cell'
                                                                    "
                                                                >
                                                                    <a
                                                                        *ngIf="
                                                                            e.link;
                                                                            else elseDiv
                                                                        "
                                                                        [href]="
                                                                            e.link
                                                                        "
                                                                        target="_blank"
                                                                    >
                                                                        <h3
                                                                            *ngIf="
                                                                                e.title
                                                                            "
                                                                        >
                                                                            {{
                                                                                e
                                                                                    .title[
                                                                                    defaultLang
                                                                                ]
                                                                            }}
                                                                        </h3>
                                                                        <span
                                                                            *ngIf="
                                                                                e.subtitle
                                                                            "
                                                                            innerHTML="
																			{{ e.subtitle[defaultLang] }}"
                                                                        ></span>
                                                                    </a>
                                                                    <ng-template
                                                                        #elseDiv
                                                                    >
                                                                        <h3
                                                                            *ngIf="
                                                                                e.title
                                                                            "
                                                                        >
                                                                            {{
                                                                                e
                                                                                    .title[
                                                                                    defaultLang
                                                                                ]
                                                                            }}
                                                                        </h3>
                                                                        <span
                                                                            *ngIf="
                                                                                e.subtitle
                                                                            "
                                                                            innerHTML="
																			{{ e.subtitle[defaultLang] }}"
                                                                        ></span>
                                                                    </ng-template>
                                                                </div>
                                                                <input
                                                                    class="form-control"
                                                                    placeholder="section.element.title"
                                                                    [value]="
                                                                        e.title[
                                                                            defaultLang
                                                                        ]
                                                                    "
                                                                    (change)="
                                                                        onTextChange(
                                                                            e.title,
                                                                            defaultLang,
                                                                            $event
                                                                        )
                                                                    "
                                                                />
                                                                <textarea
                                                                    class="form-control"
                                                                    placeholder="section.element.subtitle"
                                                                    [value]="
                                                                        e
                                                                            .subtitle[
                                                                            defaultLang
                                                                        ]
                                                                    "
                                                                    (change)="
                                                                        onTextChange(
                                                                            e.subtitle,
                                                                            defaultLang,
                                                                            $event
                                                                        )
                                                                    "
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <app-button
                                                            (action)="
                                                                addSectionElement(
                                                                    infoSections.indexOf(
                                                                        section
                                                                    ),
                                                                    section.elements.indexOf(
                                                                        e
                                                                    )
                                                                )
                                                            " 
                                                            [buttonText]="'Registry.AddElementAbove'"
                                                        />
                                                        <app-button
                                                            (action)="
                                                                addSectionElement(
                                                                    infoSections.indexOf(
                                                                        section
                                                                    ),
                                                                    section.elements.indexOf(
                                                                        e
                                                                    ) + 1
                                                                )
                                                            "
                                                            [buttonText]="'Registry.AddElementBelow'"
                                                        />
                                                        <app-button
                                                            (action)="
                                                                removeSectionElement(
                                                                    infoSections.indexOf(
                                                                        section
                                                                    ),
                                                                    section.elements.indexOf(
                                                                        e
                                                                    )
                                                                )
                                                            " 
                                                            [buttonText]="'Registry.RemoveElement'"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <app-button
                                                            *ngIf="
                                                            section.elements
                                                                .length === 0
                                                            "
                                                            (action)="
                                                            addSectionElement(
                                                                infoSections.indexOf(
                                                                    section
                                                                ),
                                                                0
                                                            )
                                                            "
                                                            [buttonText]="'Registry.AddSectionElement'"
                                                    />
                                                    <app-button
                                                            (action)="
                                                            addSection(
                                                                infoSections.indexOf(
                                                                    section
                                                                )
                                                            )
                                                            "
                                                            [buttonText]="'Registry.AddSectionAbove'"
                                                    />
                                                    <app-button
                                                            (action)="
                                                            addSection(
                                                                infoSections.indexOf(
                                                                    section
                                                                ) + 1
                                                            )
                                                            "
                                                            [buttonText]="'Registry.AddSectionBelow'"
                                                    />
                                                    <app-button
                                                            (action)="
                                                            removeSection(
                                                                infoSections.indexOf(
                                                                    section
                                                                )
                                                            )
                                                            "
                                                            [buttonText]="'Registry.RemoveSection'"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-12 my-4">
                                                <hr />
                                            </div>
                                        </div>
                                        <app-button
                                            *ngIf="infoSections.length === 0"
                                            (action)="addSection(0)"
                                            [buttonText]="'Registry.AddSection'"
                                        />
                                        
                                        <!-- END INFO SECTIONS -->

                                        <div class="col-12 mb-2">
                                            <h2>
                                                'Welcome.AboutHealthie' |
                                                translate }}
                                            </h2>
                                        </div>

                                        <div class="col-12 mb-3">
                                            <div class="row pl-3">
                                                <div class="d-table">
                                                    <div class="d-table-row">
                                                        <div
                                                            class="d-table-cell pr-3"
                                                        >
                                                            <div
                                                                class="badge badge-primary d-flex align-items-center justify-content-center"
                                                            >
                                                                <i
                                                                    class="fas fa-school"
                                                                ></i>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="d-table-cell pl-5"
                                                        >
                                                            <h3>
                                                                {{'Welcome.Privacy'
                                                                | translate }}
                                                            </h3>
                                                            <span>
                                                                {{'Welcome.PrivacyDetails'
                                                                | translate
                                                                }}</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 mb-3">
                                            <div class="row pl-3">
                                                <div class="d-table">
                                                    <div class="d-table-row">
                                                        <div
                                                            class="d-table-cell pr-3"
                                                        >
                                                            <div
                                                                class="badge badge-primary d-flex align-items-center justify-content-center"
                                                            >
                                                                <i
                                                                    class="fas fa-school"
                                                                ></i>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="d-table-cell pl-5"
                                                        >
                                                            <h3>
                                                                'Welcome.Platform'
                                                                | translate }}
                                                            </h3>
                                                            <span
                                                                innerHTML="
																'Welcome.PlatformDetails'
																	| translate
															}}"
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Right Panel - End -------------------------------------------- -->
                            </div>
                        </div>
                    </app-loading-component>
                </div>
            </div>
        </div>
    </div>
</div>

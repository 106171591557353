import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
    // Input properties
    @Input() buttonText: string = 'Select File';
    @Input() disabled: boolean = false; // To control the disabled state
    // Output events to notify parent component
    @Output() fileSelected = new EventEmitter<File>(); // Emit when a file is selected
    @Input() uploadFileWarningMsg: string = 'test';
    @Input() isEditing; // Indicates if the file is being edited
    @Input() fileSelectedEdit: File | null = null;
    // Local state for selected file and warnings
    selectedFileName: File | null = null; // Store the selected file name
    uploadPatientFile: boolean = false;
    uploadedFile: File | null = null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['fileSelectedEdit'] && changes['fileSelectedEdit'].currentValue) {
            // If a file is passed from the parent, set it as the selected file
            //this.uploadedFile = changes['fileSelectedEdit'].currentValue;
            this.uploadedFile = this.fileSelectedEdit;
        }
    }

    // Handle file selection
    onFileSelected(event: Event) {
        //this.fileBtn = true;
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            this.uploadedFile = file; // Set selected file name
            this.fileSelected.emit(file);
        }
    }
}

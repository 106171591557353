import { Component, OnInit } from '@angular/core';
import { ClaimService } from '@h20-services/claim.service';
import { Offcanvas } from 'bootstrap';

@Component({
    selector: 'app-layout-sidebar',
    templateUrl: './layout-sidebar.component.html',
    styleUrls: ['./layout-sidebar.component.scss'],
})
export class LayoutSidebarComponent implements OnInit {
    userClaims: any;
    constructor(private claimSvc: ClaimService) {}

    ngOnInit(): void {
        this.userClaims = this.claimSvc.getUserClaims() || {};
        this.offCanvasListener('offcanvas');
    }

    offCanvasListener(offCanvasId) {
        let myOffCanvas = document.getElementById(offCanvasId);
        const hideCanvas = (event) => {
            let openedCanvas = Offcanvas.getInstance(myOffCanvas);
            openedCanvas.hide();
            event.target.removeEventListener('mouseleave', hideCanvas);
        };
        const listenToMouseLeave = (event) => {
            event.target.addEventListener('mouseleave', hideCanvas);
        };
        myOffCanvas.addEventListener('shown.bs.offcanvas', listenToMouseLeave);
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistryService } from '@h20-services/registry.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BadgeTooltip } from '@h20-shared/button/button.component';
import { CommonService, EnvType } from '@h20-services/common.service';
import { SiteService } from '@h20-services/site.service';
import { DeployService } from '@h20-services/deploy.service';
import { IConfigTable } from '@h20-services/models/deploy/IConfigTable';
import { ITaskPrototype } from '@h20-services/models/tasks/ITaskPrototype';
import { TaskService } from '@h20-services/task.service';
import { UuidService } from '@h20-services/uuid.service';
import { composerUtils } from '@core/composerUtils';
import { ToastService } from '@h20-shared/services/toast.service';
import { EnvironmentService } from '@h20-services/environment.service';
import { ConfigService } from '@h20-services/config.service';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-registry-site',
    templateUrl: './registry-site.component.html',
    styleUrls: ['./registry-site.component.scss'],
})
export class RegistrySiteComponent implements OnInit {
    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private regSvc: RegistryService,
        private fb: FormBuilder,
        private siteSvc: SiteService,
        private deployService: DeployService,
        private taskService: TaskService,
        private uuid: UuidService,
        private commonSvc: CommonService,
        private toastService: ToastService,
        private environmentSvc: EnvironmentService,
        public translate: TranslateService,
        private config_svc: ConfigService
    ) {
        this.environmentSvc.currentSource.subscribe((environment) => {
            this.changeEnv(environment);
        });
    }

    loading: boolean = false;
    targetEnvs = this.commonSvc.listTargetEnvs();
    targetEnv: string;
    siteConfigDeployEnv: string;

    showDeployTo: boolean = false;
    showAddEdit: boolean = false;

    registries: any[] = [];
    registryTypes: any[];
    registryKeys: any[];
    siteTypes: any[];

    registryForm: FormGroup;
    viewRegistryPanel = false;
    isNewRegistry = false;
    selectedIsValid = false;
    submittingSiteConfigs = false;

    siteForm: FormGroup;
    viewSitePanel = false;
    isNewSite = false;

    message: string = '';

    loading_configTables: boolean = false;
    loading_copyDeployConfig: boolean = false;
    copySiteConfigForm: FormGroup;
    viewCopySiteConfigPanel: boolean = false;
    viewDeployRegistryConfigPanel: boolean = false;
    copyRegistryKey: string;
    copyDestSiteKey: string;
    copySourceSites: any[];
    sourceSiteKey: string;
    newConsentEnvelopeId: string;
    newConsentName: string;

    viewDeploySiteConfigPanel: boolean = false;
    deploySiteConfigForm: FormGroup;
    deployRegistryConfigForm: FormGroup;
    deployRegistryKey: string;
    deploySiteKey: string;
    deployResultMsgList: any[] = [];

    //registry selector
    allRegistries: any[];
    listRegistries: string[];
    selectedRegistry: string;
    loadingTable = false;

    configTables: IConfigTable[];
    configTableSelection: any = {};
    selectedConfigTables: any[] = [];
    tablesExcludeFromCopy = ['user_claim', 'claim', 'task_set_config', 'registry_config'];
    copyMessage: string = '';

    filtersOn = 0;
    temp: any[] = [];
    filterForm: FormGroup = this.fb.group({
        registryFilter: [''],
        siteFilter: [''],
    });

    colDefs = this.getTableColumns();

    ngOnInit(): void {
        this.loadingTable = true;
        this.listRegistryAndSite().finally(() => {
            this.allRegistries = this.registries;
            this.environmentSvc.currentRegistry.subscribe((environment) => {
                this.loadRegistrationContent(environment);
            });
            this.listRegistryType();
            this.loadingTable = false;
        });
        this.enableAddEdit();
        this.enableDeployTo();
        this.filterForm.valueChanges.subscribe((filterData: any) => {
            this.filter(filterData);
        });
        this.colDefs = this.getTableColumns();
    }

    getTableColumns() {
        return [
            {
                headerName: 'Registry ID',
                field: 'registry_ID',
            },
            {
                headerName: 'Registry',
                field: 'registry_key',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Site Id',
                field: 'site_ID',
            },
            {
                headerName: 'Site',
                field: 'site_name',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Edit',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                label: 'Edit Site icon',
                                iconClass: 'fas fa-pencil-alt',
                                isVisible: true,
                                onClick: () => this.showAddEditSite(params.data),
                            },
                        ],
                    };
                },
            },
            {
                headerName: 'Delete',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                label: 'Delete icon',
                                iconClass: 'fas fa-times',
                                isVisible: true,
                                onClick: () => this.deleteRegistrySite(params.data),
                            },
                        ],
                    };
                },
            },
            this.showDeployTo && {
                headerName: 'Deploy to',
                field: 'id',
                cellDataType: 'actionBtn',
                cellRendererParams: (params) => {
                    return {
                        buttonText: 'Deploy Registry',
                        onClick: () =>this.openDeployRegistryConfig(params.data),
                    };
                },
            },
            {
                headerName: 'Copy Site Config',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                label: 'Copy site icon',
                                iconClass: 'fas fa-copy',
                                isVisible: true,
                                onClick: () => this.openCopySiteConfig(params.data),
                            },
                        ],
                    };
                },
            },
            {
                headerName: 'Deploy Site Config',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                label: 'Copy site icon',
                                iconClass: 'fas fa-globe',
                                isVisible: true,
                                onClick: () => this.openDeploySiteConfig(params.data),
                            },
                        ],
                    };
                },
            },
        ]?.filter(Boolean);
    }

    //---- List registries and sites ----------------------
    async listRegistryAndSite(): Promise<void> {
        this.loading = true;
        try {
            this.siteTypes = await this.siteSvc.listSiteTypes(this.targetEnv).toPromise();
            const regSites = await this.regSvc.listRegistryAndSite(this.targetEnv).toPromise();
            if (regSites) {
                this.registries = regSites;
                this.temp = [...this.registries];
                this.checkCanDeploy();
            } else {
                this.registries = [];
                this.temp = [];
            }

            const regs = await this.regSvc.listRegistry(this.targetEnv).toPromise();
            if (regs) this.registryKeys = regs;

            this.loading = false;
        } catch (err) {
            this.registries = [];
            this.setErrorHandler(
                err,
                this.targetEnv,
                `*Error: Failed to list registry and site on ${this.targetEnv}.`
            );
        }
    }

    async checkCanDeploy(): Promise<void> {
        if (this.showDeployTo) {
            const deployTargetEnvs = this.targetEnvs.filter((e) => e !== this.targetEnv);
            let errorMsg = '';
            for (const tenv of deployTargetEnvs) {
                try {
                    const targetRegs = await this.regSvc.listRegistryAndSite(tenv).toPromise();
                    if (targetRegs) {
                        this.registries.map((reg) => {
                            const exist = targetRegs.find((r) => r.site_name === reg.site_name);
                            if (!exist) reg[`canDeploy_${tenv}`] = true;
                            else reg[`canDeploy_${tenv}`] = false;
                        });
                    }
                } catch (err) {
                    console.error(err);
                    errorMsg += `*Error: Failed to check 'Deploy to' in ${tenv}. `;
                }
            }
            if (errorMsg !== '') this.setErrorHandler(null, this.targetEnv, errorMsg);
        }
    }

    listRegistryType(): void {
        this.regSvc.listRegistryType(this.targetEnv).subscribe((registryTypes: any) => {
            if (registryTypes) {
                this.registryTypes = registryTypes;
            }
        });
    }

    enableAddEdit() {
        this.showAddEdit = this.targetEnv == EnvType.Development || EnvType.Devops ? true : false;
    }

    enableDeployTo() {
        this.showDeployTo = this.targetEnv == EnvType.Development || EnvType.Devops ? true : false;
    }

    //---- Add a new registry or site --------------------
    newRegistry(): void {
        this.registryForm = this.fb.group(
            {
                registryName: ['', [Validators.required]],
                registryKey: ['', [Validators.required]],
                siteLabel: ['', [Validators.required]],
                siteKey: ['', [Validators.required]],
                registryTypeID: ['', [Validators.required]],
            },
            { validator: this.checkKeys }
        );

        this.viewRegistryPanel = true;
        this.isNewRegistry = true;
    }

    cancelNewRegistry(): void {
        this.viewRegistryPanel = false;
        this.isNewRegistry = false;
    }

    addRegistry(context): void {
        context.regSvc
            .addRegistryAndSite(context.registryForm.value, context.targetEnv)
            .toPromise()
            .then((resp: any) => {
                context.viewRegistryPanel = false;
                context.isNewRegistry = false;
                context.listRegistryAndSite();
            })
            .catch((err) => {
                context.setErrorHandler(err, context.targetEnv);
            });
    }

    newSite(): void {
        this.siteForm = this.fb.group(
            {
                registry_ID: ['', [Validators.required]],
                siteLabel: ['', [Validators.required]],
                siteKey: ['', [Validators.required]],
                siteWorkPhone: [''],
                siteStreetAddress: [''],
                siteStreetAddress2: [''],
                siteStateProvince: [''],
                siteCity: [''],
                siteZip: [''],
                siteCountry: [''],
                siteCounty: [''],
                siteTypeId: [''],
                siteUrl: [''],
            },
            { validator: this.checkKeys }
        );
        this.viewSitePanel = true;
        this.isNewSite = true;
    }

    cancelNewSite(): void {
        this.viewSitePanel = false;
        this.isNewSite = false;
    }

    addSite(context): void {
        this.loading = true;
        context.siteSvc
            .createSite(context.siteForm.value, context.targetEnv)
            .toPromise()
            .then((resp: any) => {
                context.viewSitePanel = false;
                context.isNewSite = false;
                context.listRegistryAndSite();
                this.loading = false;
            })
            .catch((err) => {
                context.setErrorHandler(err, context.targetEnv);
                this.loading = false;
            });
    }

    // Delete only the selected site if the registry has multiple sites
    // If the registry has only one site, delete the registry and site
    async deleteRegistrySite(row) {
        try {
            const regList = this.registries.filter((temp) => temp.registry_ID === row.registry_ID);
            if (regList.length === 1) {
                if (
                    confirm(
                        `Are you sure to delete '${row.registry_key}' and all sites in ${this.targetEnv}?`
                    )
                ) {
                    await this.regSvc.deleteRegistryAndSite(row, this.targetEnv).toPromise();
                    await this.listRegistryAndSite();
                }
            } else if (regList.length > 1) {
                if (confirm(`Are you sure to delete '${row.site_name} in ${this.targetEnv}'?`)) {
                    await this.siteSvc.deleteSite(row.site_ID, this.targetEnv).toPromise();
                    await this.listRegistryAndSite();
                }
            } else {
                this.message = 'No data to delete';
            }
        } catch (err) {
            this.setErrorHandler(err, this.targetEnv);
        }
    }

    //---- Deploy RDS tables -------------------------------------------
    async submitDeployRegistryConfig(context) {
        try {
            const deployEnv = context.deployRegistryConfigForm.value.siteConfigDeployEnv;
            const reg = await context.regSvc.getRegistryByKey(context.deployRegistryKey, deployEnv).toPromise();
            if (reg.length > 0) {
                if (
                    confirm(
                        `Are you sure to deploy the site '${context.siteForm.value.siteLabel}' to ${deployEnv}?`
                    )
                ) {
                    const siteData = {
                        registry_ID: context.siteForm.value.registry_ID,
                        site_ID: context.siteForm.value.site_ID,
                        siteLabel: context.siteForm.value.siteLabel,
                        siteKey: context.siteForm.value.siteKey,
                        siteWorkPhone: context.siteForm.value.siteWorkPhone,
                        siteStreetAddress: context.siteForm.value.siteStreetAddress,
                        siteStreetAddress2: context.siteForm.value.siteStreetAddress2,
                        siteStateProvince: context.siteForm.value.siteStateProvince,
                        siteCity: context.siteForm.value.siteCity,
                        siteZip: context.siteForm.value.siteZip,
                        siteCountry: context.siteForm.value.siteCountry,
                        siteCounty: context.siteForm.value.siteCounty,
                        siteTypeId: context.siteForm.value.siteTypeId,
                        siteUrl: context.siteForm.value.siteUrl,
                    };
                    await context.siteSvc.createSite(siteData, deployEnv).toPromise();
                    await context.listRegistryAndSite();
                    context.message = `${context.siteForm.value.siteKey} has been deployed to ${deployEnv}.`;
                }
            } else {
                if (
                    confirm(`Are you sure to deploy the registry '${context.deployRegistryKey}' to ${deployEnv}?`)
                ) {
                    const registryData = {
                        registry_ID: context.siteForm.value.registry_ID,
                        site_ID: context.siteForm.value.site_ID,
                        registryName: context.siteForm.value.registryName,
                        registryKey: context.siteForm.value.registryKey,
                        registryTypeID: context.siteForm.value.registryTypeID,
                        siteLabel: context.siteForm.value.siteLabel,
                        siteKey: context.siteForm.value.siteKey
                    };
                    await context.regSvc.addRegistryAndSite(registryData, deployEnv).toPromise();
                    await context.listRegistryAndSite();
                    context.message = `${context.siteForm.value.registryKey} has been deployed to ${deployEnv}.`;
                }
            }
        } catch (err) {
            context.setErrorHandler(err, context.targetEnv);
        }
    }

    //---- Helper Functions ---------------------------------------
    changeEnv(env) {
        this.message = '';
        this.targetEnv = env;
        this.enableAddEdit();
        this.enableDeployTo();
        this.listRegistryAndSite();
    }

    checkKeys = (group: FormGroup): any => {
        const inputs = group.value;
        const registryKey = group.get('registryKey');
        const siteKey = group.get('siteKey');
        let flag = null;
        const upperReg = /[A-Z]/g.test(inputs.registryKey);
        const specialReg = /[@~`!#$%\^&*+=\\[\]\\';,./{}|\\":<>\?]/g.test(inputs.registryKey);
        const upperSite = /[A-Z]/g.test(inputs.siteKey);
        const specialSite = /[@~`!#$%\^&*+=\\[\]\\';,./{}|\\":<>\?]/g.test(inputs.siteKey);

        if (upperReg || specialReg) {
            flag = { noMatch: true };
            registryKey.setErrors(flag);
        } else if (upperSite || specialSite) {
            flag = { noMatch: true };
            siteKey.setErrors(flag);
        }
        return flag;
    };

    setErrorHandler(err, env, msg: string = '') {
        if (msg === '') {
            this.message = `An error occurs on ${env}`;
        } else {
            this.message = `${msg}`;
        }
        if (err) console.error(err);
        if (this.loading) this.loading = false;
    }

    showAddEditSite(row) {
        this.isNewSite = false;
        this.siteForm = this.fb.group(
            {
                registry_ID: [row.registry_ID, [Validators.required]],
                site_ID: [row.site_ID],
                siteLabel: [row.site_label, [Validators.required]],
                siteKey: [row.site_name, [Validators.required]],
                siteWorkPhone: [row.work_phone],
                siteStreetAddress: [row.site_street_address],
                siteStreetAddress2: [row.site_street_address_2],
                siteStateProvince: [row.state_province_text],
                siteCity: [row.city_text],
                siteZip: [row.zip],
                siteCountry: [row.country_text || ''],
                siteCounty: [row.county_text],
                siteTypeId: [row.site_type_ID || ''],
                siteUrl: [row.url],
            },
            { validator: this.checkKeys }
        );
        this.viewSitePanel = true;
    }

    editSite() {
        this.siteSvc
            .updateSite(this.siteForm.value, this.targetEnv)
            .toPromise()
            .then((resp: any) => {
                this.viewSitePanel = false;
                this.isNewSite = false;
                this.listRegistryAndSite();
            })
            .catch((err) => {
                this.setErrorHandler(err, this.targetEnv);
            });
    }

    // // Filter option functions
    // trayToggle($ev: string): any {
    //     this.togglePane = $ev;
    //     this.toggleFilters = !this.toggleFilters;
    // }

    // innerToggle($ev: boolean): any {
    //     this.togglePane = '';
    //     this.toggleFilters = $ev;
    // }

    filter(filterData) {
        this.registries = this.temp.filter((reg: any) => {
            const regsFlag = composerUtils.filterIncludes(
                reg.registry_key,
                filterData.registryFilter
            );
            const siteFlag = composerUtils.filterIncludes(reg.site_name, filterData.siteFilter);
            return regsFlag && siteFlag;
        });
    }

    // Copy & Deploy Site Configuration Functions
    loadConfigTables() {
        this.loading_configTables = true;
        this.deployService
            .listConfigTable(this.targetEnv)
            .toPromise()
            .then((res) => {
                this.configTables = res.filter(
                    (tab: any) => !this.tablesExcludeFromCopy.includes(tab.tableKey)
                );
                this.loading_configTables = false;
            })
            .catch((err) => {
                this.loading_configTables = false;
                this.setErrorHandler(err, `Cannot load config tables on ${this.targetEnv}`);
                if (this.configTables) this.configTables = [];
            });
    }

    async loadConfigItems(configTableKey: string, mode: string, context?): Promise<void> {
        try {
            let selectedTable: any;
            let destConfigItems: any[] = [];
            this.configTables.map((ct) => {
                if (ct.tableKey === configTableKey) selectedTable = ct;
            });
            const configRes = await this.deployService //this is the table in the tget env
                .getConfigItems(configTableKey, this.targetEnv)
                .toPromise();
            const pkNameSplit = selectedTable.partitionKeyName.split('#');

            let siteIndex = null;
            for (let i = 0; i < pkNameSplit.length; i++) {
                if (
                    pkNameSplit[i].toLowerCase() === 'site_id' ||
                    pkNameSplit[i].toLowerCase() === 'site'
                ) {
                    siteIndex = i;
                }
            }

            for (const configIt of configRes) {
                const pkSplit = configIt[selectedTable.partitionKeyName].split('#');
                if (pkSplit[siteIndex] === this.sourceSiteKey) {
                    if (mode === 'copy') {
                        let newPK = '';
                        let resetManagedSite = false;
                        for (let i = 0; i < pkSplit.length; i++) {
                            if (i === siteIndex && pkSplit[i] === this.sourceSiteKey) {
                                newPK += this.copyDestSiteKey;
                            } else {
                                newPK += pkSplit[i];
                            }
                            newPK += '#';
                            if (
                                selectedTable.tableKey === 'role_claim' &&
                                (pkSplit[i] === 'site-admin' || pkSplit[i] === 'clinical')
                            ) {
                                resetManagedSite = true;
                            }
                        }
                        newPK = newPK.slice(0, -1);
                        configIt[selectedTable.partitionKeyName] = newPK;

                        // Reset managed_sites for the new site's site-admin
                        if (resetManagedSite && configIt['managed_sites']) {
                            const managedSites: string[] = [this.copyDestSiteKey];
                            configIt['managed_sites'] = managedSites;
                        }
                        // Reset consent envelope ID and Name
                        if (
                            selectedTable.tableKey === 'consent_config' &&
                            this.newConsentEnvelopeId
                        ) {
                            configIt['consent_template_ID'] = this.newConsentEnvelopeId;
                        }
                        if (selectedTable.tableKey === 'consent_config' && this.newConsentName) {
                            configIt['consent_name'] = this.newConsentName;
                        }
                        // For schedule config, change site_ID in data
                        if (selectedTable.tableKey === 'schedule') {
                            configIt['data']['site_ID'] = this.copyDestSiteKey;
                            configIt['data']['title'] =
                                `${this.copyDestSiteKey} - ${this.copyRegistryKey}`;
                            configIt['data']['registry'] = this.copyRegistryKey
                                ? this.copyRegistryKey
                                : this.copyDestSiteKey;
                            if (configIt['data']['graph']['taskMap']) {
                                for (
                                    let i = 0;
                                    i < configIt['data']['graph']['taskMap'].length;
                                    i++
                                ) {
                                    configIt['data']['graph']['taskMap'][i]['task']['site_ID'] =
                                        this.copyDestSiteKey;
                                }
                            }
                        }
                        destConfigItems.push(configIt);
                    } else {
                        destConfigItems.push(configIt);
                    }
                }
            }

            if (destConfigItems.length === 0) {
                this.copyMessage = `${selectedTable.tableKey} has no data for '${this.sourceSiteKey}'`;
            } else if (
                context.targetEnv === EnvType.Devops &&
                context.siteConfigDeployEnv === EnvType.Development &&
                mode === 'deploy'
            ) {
                this.copyMessage = `Started the progress: ${selectedTable.tableKey}`;

                let newestSendToDestItem = destConfigItems[0];
                destConfigItems.forEach((item) => {
                    if (newestSendToDestItem?.version < item.version) newestSendToDestItem = item;
                });
                const configsBeingReplaced = await this.deployService
                    .getConfigItems(configTableKey, context.siteConfigDeployEnv)
                    .toPromise();
                const pkSplit = newestSendToDestItem[selectedTable.partitionKeyName].split('#');

                for (const configBeingSaved of configsBeingReplaced) {
                    const dpkSplit = configBeingSaved[selectedTable.partitionKeyName].split('#');
                    if (
                        dpkSplit[siteIndex] === pkSplit[siteIndex] &&
                        newestSendToDestItem.latest_version < configBeingSaved.latest_version &&
                        configBeingSaved.version === 0
                    ) {
                        this.versionSavedDeployConfigItem(
                            selectedTable,
                            configBeingSaved,
                            newestSendToDestItem,
                            context.siteConfigDeployEnv
                        );
                    }
                }

                this.copyMessage = `Finished the progress: ${selectedTable.tableKey}`;
            } else {
                this.copyMessage = `Started the progress: ${selectedTable.tableKey}`;
                for (const dItem of destConfigItems) {
                    let copyItemIdx = 1;
                    if (mode === 'copy') await this.copyConfigItem(selectedTable, dItem);
                    else await this.deployConfigItem(selectedTable, dItem);

                    if (copyItemIdx === destConfigItems.length) {
                        this.copyMessage = `Finished the progress: ${selectedTable.tableKey}`;
                    } else {
                        copyItemIdx++;
                    }
                }
            }
        } catch (err) {
            this.setErrorHandler(err, 'An error occurs.');
            this.clearSelectedData();
        }
    }

    checkConfigTable(item, checked) {
        this.configTableSelection[item] = checked;
        this.updateSelectedIsValid();
    }

    updateSelectedIsValid() {
        this.selectedIsValid = Object.values(this.configTableSelection).some((value) => value);
    }

    cancelCopySiteConfig(): void {
        this.viewCopySiteConfigPanel = false;
        this.clearSelectedData();
    }

    openCopySiteConfig(row): void {
        this.viewCopySiteConfigPanel = true;
        this.copyRegistryKey = row.registry_key;
        this.copyDestSiteKey = row.site_name;
        this.copySourceSites = this.temp.filter(
            (reg) => reg.registry_key === row.registry_key && reg.site_name !== this.copyDestSiteKey
        );
        this.copySiteConfigForm = this.fb.group(
            {
                sourceSite: ['', [Validators.required]],
            },
            { validator: this.checkKeys }
        );

        this.loadConfigTables();
    }

    submitCopySiteConfig(context) {
        context.sourceSiteKey = context.copySiteConfigForm.value.sourceSite;
        if (Object.keys(context.configTableSelection).length == 0) {
            context.copyMessage = 'Please select a table';
        } else {
            context.loading_copyDeployConfig = true;
            context.copyMessage = 'Started copying configs. It will take some time...';
            let promises = [];
            for (let tableKey in context.configTableSelection) {
                if (context.configTableSelection[tableKey]) {
                    if (tableKey === 'task_prototype') {
                        promises.push(context.copyTaskPrototype());
                    } else {
                        promises.push(context.loadConfigItems(tableKey, 'copy', context));
                    }
                }
            }
            Promise.all(promises).then(() => {
                context.clearSelectedData();
                context.viewCopySiteConfigPanel = false;
                context.message = 'Site configurations have been copied successfully.';
            });
        }
    }

    async copyConfigItem(selectedTable, destItem): Promise<void> {
        let configItemRest = {};
        try {
            const existItem = await this.deployService
                .getConfigItem(
                    selectedTable.tableKey,
                    destItem[selectedTable.partitionKeyName],
                    this.targetEnv
                )
                .toPromise();

            const {
                [selectedTable.partitionKeyName]: removePK,
                ['version']: removeSK,
                ['latest_version']: removeLatestVersion,
                ['created']: removeCreated,
                ...rest
            } = destItem;
            configItemRest = rest;

            if (existItem) {
                await this.deployService
                    .updateConfigItem(
                        selectedTable.tableKey,
                        destItem[selectedTable.partitionKeyName],
                        null, // Should be null to increment by 1
                        configItemRest,
                        this.targetEnv
                    )
                    .toPromise();
            } else {
                await this.deployService.createConfigItem(
                    selectedTable.tableKey,
                    destItem[selectedTable.partitionKeyName],
                    null, // Should be null to be version 1
                    configItemRest,
                    this.targetEnv
                );
            }
        } catch (err) {
            this.setErrorHandler(err, 'An error occurs.');
            this.clearSelectedData();
        }
    }

    cancelDeploySiteConfig(): void {
        this.siteConfigDeployEnv = undefined;
        this.viewDeploySiteConfigPanel = false;
        this.clearSelectedData();
    }

    openDeploySiteConfig(row): void {
        this.viewDeploySiteConfigPanel = true;
        this.deployRegistryKey = row.registry_key;
        this.deploySiteKey = row.site_name;
        this.sourceSiteKey = row.site_name;

        this.deploySiteConfigForm = this.fb.group(
            {
                siteConfigDeployEnv: ['', [Validators.required]],
            },
            { validator: this.checkKeys }
        );
        this.loadConfigTables();
    }

    submitDeploySiteConfig(context) {
        context.submittingSiteConfigs = true;
        context.siteConfigDeployEnv = context.deploySiteConfigForm.value.siteConfigDeployEnv;
        if (context.siteConfigDeployEnv === context.targetEnv) {
            context.copyMessage = 'Error: Source and Destination env should be different.';
            context.submittingSiteConfigs = false;
        } else {
            if (confirm(`Are you sure you want to deploy to ${context.siteConfigDeployEnv}?`)) {
                context.loading_copyDeployConfig = true;
                context.copyMessage = 'Started deploying configs. It will take some time...';
                let promises = [];
                for (let tableKey in context.configTableSelection) {
                    if (context.configTableSelection[tableKey]) {
                        if (tableKey === 'task_prototype') {
                            promises.push(context.deployTaskPrototypes());
                        } else {
                            promises.push(context.loadConfigItems(tableKey, 'deploy', context));
                        }
                    }
                }
                Promise.all(promises).then(() => {
                    context.clearSelectedData();
                    context.siteConfigDeployEnv = undefined;
                    context.viewDeploySiteConfigPanel = false;
                    context.submittingSiteConfigs = false;
                });
            }
        }
    }

    async deployConfigItem(selectedTable, deployItem): Promise<void> {
        try {
            const deployResult = await this.deployService.deployConfigItem(
                selectedTable,
                deployItem,
                this.siteConfigDeployEnv
            );
            this.deployResultMsgList.push(deployResult.message);
        } catch (err) {
            this.setErrorHandler(err, 'An error occurs.');
            this.clearSelectedData();
        }
    }

    async versionSavedDeployConfigItem(
        configTable: any,
        newerItemOnDest: any,
        olderItemOnSource: any,
        env: string
    ): Promise<any> {
        try {
            const {
                [configTable.partitionKeyName]: replaceConfigPKey,
                ['version']: replaceConfigVersion,
                ['latest_version']: replaceConfigLatestVersion,
                ...replaceSourceItem
            } = newerItemOnDest;

            const {
                [configTable.partitionKeyName]: newConfigPKey,
                ['version']: newConfigVersion,
                ...newSourceItem
            } = olderItemOnSource;

            const deployResult = await this.deployService.versionSavedDeployConfigItem(
                configTable.tableKey,
                replaceConfigPKey,
                replaceConfigVersion,
                newSourceItem,
                replaceSourceItem,
                env
            );

            this.deployResultMsgList.push(deployResult.message);
        } catch (err) {
            this.setErrorHandler(err, 'An error occurs.');
            this.clearSelectedData();
        }
    }

    async deleteConfigItems(selectedTable: string, partitionKeyName: string, env: string) {
        try {
            const deleteResult = await this.deployService.deleteConfigItem(
                selectedTable,
                partitionKeyName
            );
        } catch (err) {
            this.setErrorHandler(err, 'An error occurs.');
            this.clearSelectedData();
        }
    }

    // Copy & Deploy Task Prototype and Task Set functions
    async copyTaskPrototype(): Promise<void> {
        try {
            const destPrototypes = await this.taskService
                .getTaskPrototypeBySite(this.copyDestSiteKey)
                .toPromise();

            const sourcePrototypes = await this.taskService
                .getTaskPrototypeBySite(this.sourceSiteKey)
                .toPromise();

            if (destPrototypes && destPrototypes.length !== 0) {
                this.copyMessage = `Task prototypes of ${this.copyDestSiteKey} already exist`;
            } else if (sourcePrototypes && sourcePrototypes.length === 0) {
                this.copyMessage = `There is no task prototypes of ${this.sourceSiteKey}`;
            } else {
                for (let i = 0; i < sourcePrototypes.length; i++) {
                    const copyItem: ITaskPrototype = sourcePrototypes[i];
                    const sourcePrototypeId = copyItem.id;
                    const destPrototypeId = this.uuid.uuid();

                    copyItem.site_ID = this.copyDestSiteKey;
                    copyItem.id = destPrototypeId;
                    await this.taskService
                        .createTaskPrototype(copyItem, this.targetEnv)
                        .toPromise();

                    if (i === sourcePrototypes.length - 1) {
                        this.copyMessage = `Copied task_prototype successfully.`;
                    }
                }
            }
        } catch (err) {
            this.setErrorHandler(err, 'An error occurs : task_prototype');
            this.clearSelectedData();
        }
    }

    async deployTaskPrototypes(): Promise<void> {
        const sourcePrototypes = await this.taskService
            .getTaskPrototypeBySite(this.deploySiteKey, this.targetEnv)
            .toPromise();

        if (sourcePrototypes.length === 0) {
            this.copyMessage = `There is no task prototypes of ${this.deploySiteKey}`;
        } else {
            try {
                for (let i = 0; i < sourcePrototypes.length; i++) {
                    const deployItem: ITaskPrototype = sourcePrototypes[i];

                    const existItem = await this.taskService
                        .getTaskPrototype(deployItem.id, this.siteConfigDeployEnv)
                        .toPromise();

                    if (existItem && existItem.length !== 0) {
                        let destItem: ITaskPrototype; 
                        for(let i of existItem){
                            if(i.version === 0){
                                destItem = i
                            }
                        }
                        if(this.commonSvc.compareEnvs(this.targetEnv, this.siteConfigDeployEnv) < 0 && deployItem.version < destItem.latest_version){
                            await this.taskService
                            .versionSavedUpdateTaskPrototype(deployItem, this.siteConfigDeployEnv);
                        } else {
                             await this.taskService
                            .updateTaskPrototype(deployItem, this.siteConfigDeployEnv)
                            .toPromise();
                        }
                    } else {
                        await this.taskService
                            .createTaskPrototype(deployItem, this.siteConfigDeployEnv)
                            .toPromise();
                    }

                    if (i === sourcePrototypes.length - 1) {
                        this.message = `Deployed task_prototype successfully.`;
                    }
                }
            } catch (err) {
                this.setErrorHandler(err, 'An error occurs : task_prototype');
                this.clearSelectedData();
            }
        }
    }

    clearSelectedData() {
        this.configTableSelection = {};
        this.copyMessage = '';
        this.loading_copyDeployConfig = false;
        this.newConsentEnvelopeId = null;
        this.newConsentName = null;
        this.selectedIsValid = false;
    }

    showToast() {
        this.toastService.show({
            body: this.translate.instant('Toast.FilterOptions'),
        });
    }

    loadRegistrationContent(registry) {
        this.selectedRegistry = registry;
        this.registries = this.allRegistries?.filter((value) => {
            return value.registry_key === registry;
        });
    }

    cancelDeployRegistryConfig(): void {
        this.viewDeployRegistryConfigPanel = false;
    }

    openDeployRegistryConfig(row): void {
        this.viewDeployRegistryConfigPanel = true;
        this.deployRegistryKey = row.registry_key;
        this.deploySiteKey = row.site_name;
        this.deployRegistryConfigForm = this.fb.group(
            {
                siteConfigDeployEnv: ['', [Validators.required]],
            },
            { validator: this.checkKeys }
        );
        this.siteForm = this.fb.group(
            {
                registry_ID: [row.registry_ID, [Validators.required]],
                site_ID: [row.site_ID],
                siteLabel: [row.site_label, [Validators.required]],
                siteKey: [row.site_name, [Validators.required]],
                siteWorkPhone: [row.work_phone],
                siteStreetAddress: [row.site_street_address],
                siteStreetAddress2: [row.site_street_address_2],
                siteStateProvince: [row.state_province_text],
                siteCity: [row.city_text],
                siteZip: [row.zip],
                siteCountry: [row.country_text || ''],
                siteCounty: [row.county_text],
                siteTypeId: [row.site_type_ID || ''],
                siteUrl: [row.url],
                registryName: [row.registry_name],
                registryKey: [row.registry_key],
                registryTypeID: [row.registry_type_ID]
            },
            { validator: this.checkKeys }
        );
    }
}

<div *ngIf="elements[index].type === 'paneldynamic'">
    <app-element-layout-designer [(elements)]="elements[index].templateElements">
    </app-element-layout-designer>
</div>
<app-pi-form
    *ngIf="designerForm && elements"
    [formData]="initialData"
    [formDef]="designerForm"
    [options]="options"
    [parentList]="elements"
    (formChanges)="onFormChanges($event)"
    layout="flat"
></app-pi-form>

<label *ngIf="!isEditing"
class="btn label-theme text-nowrap select-file-button"
attr.aria-label="{{ 'Registry.SelectFile' | translate }}"
>
{{ 'Registry.SelectFile' | translate }}
<input
    id="uploadedFile"
    class="hidden small-text"
    [disabled]="isEditing"
    type="file"
    (change)="onFileSelected($event)"
/>

</label> 
<span *ngIf="uploadedFile" class="badge text-dark ms-2" id="uploadedFileName">
  {{  uploadedFile?.name || uploadedFile?.fileName}}
</span>
 
<div>
  <span class="text-muted" style="font-size: 80%;">
    {{'Registry.UploadedFileTypes' | translate}}</span> 
  </div> 
  <div    class="text-danger pt-2" style="font-size: 80%;">
  {{ uploadFileWarningMsg }} 
  </div>
<app-modal
    [footerText]="'Consent.ParticipationVoluntary'"
    [saveBtnText]="'Deploy Claim'"
    *ngIf="viewNewClaimPanel"
    [(visible)]="viewNewClaimPanel"
    [header]="isNewClaim ? 'Add New Claim' : 'Edit Claim'"
    [context]="this"
    [saveFunc]="isNewClaim ? addNewClaim : updateClaim"
    [closeFunc]="cancelNewClaim"
    [modalWidth]="'40vw'"
    [modalHeight]="'60vh'"
    [saveBtnDisabled]="!nuForm.valid || loading"
>
    <div class="container-fluid" [formGroup]="newClaimForm" #nuForm="ngForm">
        <div class="form-group row mt-2">
            <div class="form-group" formGroupName="required_by">
                <label class="col-2">Required By</label>
                <label
                    >H20 Platform
                    <input
                        type="checkbox"
                        class="mx-3"
                        aria-label="h20platform"
                        title="h20platform"
                        name="h20platform"
                        formControlName="h20platform"
                /></label>
                <label
                    >Composer
                    <input
                        type="checkbox"
                        class="mx-3"
                        aria-label="composer"
                        title="composer"
                        name="composer"
                        formControlName="composer"
                    />
                </label>
            </div>
            <div class="form-group row mt-2">
                <div class="col">
                    <input
                        aria-label="name"
                        placeholder="Claim Name"
                        type="text"
                        class="form-control"
                        id="name"
                        formControlName="name"
                    />
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col">
                    <textarea
                        aria-label="description"
                        placeholder="Claim Description"
                        rows="5"
                        class="form-control"
                        id="description"
                        formControlName="description"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</app-modal>
<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h1>Claims</h1>
                    <form>
                        <div class="form-group">
                            <app-loading-component
                                [loading]="claimsLoading"
                                [text]="'loading claims'"
                            >
                                <div class="ml-3 d-flex">
                                    <app-button
                                        (action)="newClaim()"
                                        [buttonText]="'Registry.AddNewClaim'"
                                    />
                                </div>
                            </app-loading-component>
                        </div>
                    </form>
                    <div class="ag-theme-quartz" style="width: 100%; margin-top: 15px">
                        <app-table-component
                            [rowData]="claims"
                            [columnDefs]="colDefs"
                            pagination="true"
                            [filterModelKey]="'claims'"
                            [showFilterRow]="false"
                        ></app-table-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MessageCenterComponent } from './message-center/message-center.component';
import { TooltipModule } from 'ng2-tooltip-directive-ngfix';
import { TrayComponent } from './tray/tray.component';
import { ButtonComponent } from './button/button.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgbModule, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { SearchableListComponent } from './searchable-list/searchable-list.component';
import { EarlyBirdComponent } from './early-bird/early-bird.component';
import { AssignSurveyTaskComponent } from './assign-survey-task/assign-survey-task.component';
import { MfaComponent } from './mfa/mfa.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ValidatorComponent } from './pi-form-elements/validator/validator.component';
import { LoadingComponent } from './loading-component/loading-component.component';
import { ModalComponent } from './modal/modal.component';
import { TrackerComponent } from './tracker/tracker.component';
import { PiFormLayoutFlatComponent } from './pi-form-layouts/pi-form-layout-flat/pi-form-layout-flat.component';
import { PiFormComponent } from './pi-form/pi-form.component';
import { PiControlSwitchComponent } from './pi-form-controls/pi-control-switch/pi-control-switch.component';
import { AbstractPiControlComponent } from './pi-form-controls/abstract-pi-control/abstract-pi-control.component';
import { PiControlRadiogroupComponent } from './pi-form-controls/pi-control-radiogroup/pi-control-radiogroup.component';
import { PiControlTextComponent } from './pi-form-controls/pi-control-text/pi-control-text.component';
import { PiControlCheckboxComponent } from './pi-form-controls/pi-control-checkbox/pi-control-checkbox.component';
import { PiControlEmailComponent } from './pi-form-controls/pi-control-email/pi-control-email.component';
import { PiControlPhoneComponent } from './pi-form-controls/pi-control-phone/pi-control-phone.component';
import { PiControlDateComponent } from './pi-form-controls/pi-control-date/pi-control-date.component';
import { PiControlNumberComponent } from './pi-form-controls/pi-control-number/pi-control-number.component';
import { PiControlDynamicPanelComponent } from './pi-form-controls/pi-control-dynamic-panel/pi-control-dynamic-panel.component';
import { PiControlCommentComponent } from './pi-form-controls/pi-control-comment/pi-control-comment.component';
import { PiControlDropdownComponent } from './pi-form-controls/pi-control-dropdown/pi-control-dropdown.component';
import { PiControlNodepersonComponent } from './pi-form-controls/pi-control-nodeperson/pi-control-nodeperson.component';
import { PiControlRatingComponent } from './pi-form-controls/pi-control-rating/pi-control-rating.component';
import { PiControlSliderComponent } from './pi-form-controls/pi-control-slider/pi-control-slider.component';
import { PiControlGestureComponent } from './pi-form-controls/pi-control-gesture/pi-control-gesture.component';
import { PiControlGestureMapComponent } from './pi-form-controls/pi-control-gesture-map/pi-control-gesture-map.component';
import { PiControlMultitextComponent } from './pi-form-controls/pi-control-multitext/pi-control-multitext.component';
import { PiControlFilepickerComponent } from './pi-form-controls/pi-control-filepicker/pi-control-filepicker.component';
import { PiControlEuroqolSliderComponent } from './pi-form-controls/pi-control-euroqol-slider/pi-control-euroqol-slider.component';
import { PiControlHtmlBuilderComponent } from './pi-form-controls/pi-control-html-builder/pi-control-html-builder.component';
import { ElementTitleComponent } from './pi-form-elements/element-title/element-title.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MapTileComponent } from './highcharts-tiles/map-tile/map-tile.component';
import { HtmlTileComponent } from './highcharts-tiles/html-tile/html-tile.component';
import { HighchartsTileTypesComponent } from './highcharts-tiles/highcharts-tile-types/highcharts-tile-types.component';
import { BaseTileComponent } from './highcharts-tiles/base-tile/base-tile.component';
import { HighchartsLayoutComponent } from './highcharts-layout/highcharts-layout.component';
import { PieTileComponent } from './highcharts-tiles/pie-tile/pie-tile.component';
import { BarTileComponent } from './highcharts-tiles/bar-tile/bar-tile.component';
import { CounterTileComponent } from './highcharts-tiles/counter-tile/counter-tile.component';
import { PiControlHtmlComponent } from './pi-form-controls/pi-control-html/pi-control-html.component';
import { PiControlDatePickerComponent } from './pi-form-controls/pi-control-date-picker/pi-control-date-picker.component';
import { TableauComponent } from './tableau/tableau.component';
import { PiFormLayoutOneByOneComponent } from './pi-form-layouts/pi-form-layout-one-by-one/pi-form-layout-one-by-one.component';
import { PiFormProgressBarComponent } from './pi-form-layouts/pi-form-progress-bar/pi-form-progress-bar.component';
import { PiFormViewResponseComponent } from './pi-form-elements/pi-form-view-response/pi-form-view-response.component';
import { PiControlLinkGeneratorComponent } from './pi-form-controls/pi-control-link-generator/pi-control-link-generator.component';
import { DatatableFiltersComponent } from './datatable-filters/datatable-filters.component';
import { A11yModule } from '@angular/cdk/a11y';
import { PiControlPartialDateComponent } from './pi-form-controls/pi-control-partial-date/pi-control-partial-date.component';
import { DragDirective } from './file-drag-drop.directive';
import { ObjectDiffComponent } from './object-diff/object-diff/object-diff.component';
import { PiControlBooleanComponent } from './pi-form-controls/pi-control-boolean/pi-control-boolean.component';
import { PiControlDropTextComponent } from './pi-form-controls/pi-control-drop-text/pi-control-drop-text.component';
import { PiControlLogicBuilderComponent } from './pi-form-controls/pi-control-logic-builder/pi-control-logic-builder.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PiControlTimeComponent } from './pi-form-controls/pi-control-time/pi-control-time.component';
import { TableComponent } from './table-component/table-component.component';
import { AgGridAngular } from 'ag-grid-angular';
import { CustomCellRendererComponent } from './table-component/cell-renderer/custom-cell-renderer/custom-cell-renderer.component';
import { CustomFilterComponent } from './table-component/custom-filter/custom-filter.component';
import { ToastComponent } from './toast/toast.component';
import { IconCardComponent } from './icon-card/icon-card.component';
import { MaskInputDirective } from './pi-form-controls/pi-control-date/mask-input.directive';
import { PiControlRelativeDateValidatorBuilderComponent } from './pi-form-controls/pi-control-relative-date-validator-builder/pi-control-relative-date-validator-builder.component';
import { FeaturedIconComponent } from './featured-icon/featured-icon.component';
import { SurveyBuilderChoiceManagerComponent } from './pi-form-controls/surveybuilder-choice-manager/surveybuilder-choice-manager.component';
import { CreatePasswordComponent } from './create-password-component/create-password-component';
import { GenericBadgeComponent } from './generic-badge/generic-badge.component';
import { PasswordComponent } from './password-component/password-component';
import { FileUploadComponent } from './file-upload/file-upload.component';

export function homeHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
@NgModule({
    declarations: [
        MessageCenterComponent,
        TrayComponent,
        ButtonComponent,
        SearchableListComponent,
        EarlyBirdComponent,
        AssignSurveyTaskComponent,
        MfaComponent,
        ValidatorComponent,
        LoadingComponent,
        ModalComponent,
        TrackerComponent,
        ElementTitleComponent,
        PiFormLayoutFlatComponent,
        PiFormComponent,
        AbstractPiControlComponent,
        PiControlSwitchComponent,
        PiControlRadiogroupComponent,
        PiControlTextComponent,
        PiControlCheckboxComponent,
        PiControlEmailComponent,
        PiControlPhoneComponent,
        PiControlDateComponent,
        PiControlNumberComponent,
        PiControlDynamicPanelComponent,
        PiControlCommentComponent,
        PiControlDropdownComponent,
        PiControlNodepersonComponent,
        PiControlRatingComponent,
        PiControlSliderComponent,
        PiControlGestureComponent,
        PiControlGestureMapComponent,
        PiControlMultitextComponent,
        PiControlFilepickerComponent,
        PiControlHtmlComponent,
        PiControlDatePickerComponent,
        PiControlHtmlBuilderComponent,
        MapTileComponent,
        HtmlTileComponent,
        PieTileComponent,
        HighchartsTileTypesComponent,
        HighchartsLayoutComponent,
        BaseTileComponent,
        BarTileComponent,
        CounterTileComponent,
        TableauComponent,
        PiFormLayoutOneByOneComponent,
        PiFormProgressBarComponent,
        PiFormViewResponseComponent,
        PiControlLinkGeneratorComponent,
        DatatableFiltersComponent,
        PiControlEuroqolSliderComponent,
        PiControlPartialDateComponent,
        DragDirective,
        ObjectDiffComponent,
        PiControlBooleanComponent,
        PiControlDropTextComponent,
        PiControlLogicBuilderComponent,
        PiControlTimeComponent,
        TableComponent,
        CustomFilterComponent,
        ToastComponent,
        IconCardComponent,
        MaskInputDirective,
        PiControlRelativeDateValidatorBuilderComponent,
        FeaturedIconComponent,
        CustomCellRendererComponent,
        SurveyBuilderChoiceManagerComponent,
        CreatePasswordComponent,
        GenericBadgeComponent,
        PasswordComponent,
        FileUploadComponent,
    ],
    providers: [NgbDatepickerConfig],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        TooltipModule,
        QRCodeModule,
        HighchartsChartModule,
        A11yModule,
        NgSelectModule,
        AgGridAngular,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: homeHttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],

    exports: [
        CommonModule,
        MessageCenterComponent,
        TrayComponent,
        ButtonComponent,
        SearchableListComponent,
        EarlyBirdComponent,
        AssignSurveyTaskComponent,
        MfaComponent,
        ValidatorComponent,
        LoadingComponent,
        ModalComponent,
        TrackerComponent,
        PiFormComponent,
        PiControlSwitchComponent,
        PiControlRadiogroupComponent,
        PiControlTextComponent,
        PiControlCheckboxComponent,
        PiControlEmailComponent,
        PiControlPhoneComponent,
        PiControlDateComponent,
        PiControlDatePickerComponent,
        PiControlNumberComponent,
        PiControlDynamicPanelComponent,
        PiControlCommentComponent,
        PiControlDropdownComponent,
        PiControlLogicBuilderComponent,
        PiControlNodepersonComponent,
        PiControlRatingComponent,
        PiControlSliderComponent,
        PiControlGestureComponent,
        PiControlGestureMapComponent,
        PiControlMultitextComponent,
        PiControlHtmlComponent,
        PiControlHtmlBuilderComponent,
        HighchartsTileTypesComponent,
        HighchartsLayoutComponent,
        TableauComponent,
        DatatableFiltersComponent,
        PiControlEuroqolSliderComponent,
        DragDirective,
        ObjectDiffComponent,
        PiControlTimeComponent,
        TableComponent,
        ToastComponent,
        IconCardComponent,
        FeaturedIconComponent,
        CustomCellRendererComponent,
        CreatePasswordComponent,
        GenericBadgeComponent,
        PasswordComponent,
        FileUploadComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], // For Embedded Tableau to work
})
export class SharedModule {}

// PulseAuth.ts
// Copyright 2023 Pulse Infoframe Inc

import { UserRole } from './role';
import { UserStatus } from './status';

export class PulseAuth {
    user_id: string = '';
    site_ID: string = '';
    emailAddr: string = '';
    tableau_username = '';
    isLoggedIn: boolean = false;
    mfa_required: boolean = false;
    mfa_status = '';
    authenticationID = '';
    preferredName = '';
    preferred_language = '';
    organizationID = '';
    type: string = '';
    status: string = '';
    isPatientProxy: boolean = false;
    greenlight_patient_ID: string = '';
    registryKey: string = '';
    claims: any;
    participants: any[] = [];

    constructor(cogUser, rdsUser) {
        if (cogUser) {
            this.isLoggedIn = true;
            if (rdsUser) {
                this.user_id = rdsUser.id || '';
                this.site_ID = rdsUser.site_ID || '';
                this.emailAddr = rdsUser.email || cogUser.username || '';
                this.type = rdsUser.type || '';
                this.authenticationID = rdsUser.authentication_ID || '';
                this.organizationID = rdsUser.organization_ID || '';
                this.status = rdsUser.status || '';
                this.mfa_required = rdsUser.mfa_required;
                this.mfa_status = rdsUser.mfa_status || '';
                this.preferredName = rdsUser.preferred_name;
                this.preferred_language = rdsUser.preferred_language;
                this.isPatientProxy = rdsUser.isPatientProxy;
                this.greenlight_patient_ID = rdsUser.greenlight_patient_ID;
                this.registryKey = rdsUser.registry_key;
            }
            this.tableau_username = cogUser.attributes?.['custom:tableau_username'] || '';
            if (!this.preferredName) this.preferredName = cogUser.attributes?.nickname;
            if (rdsUser.claims) this.claims = rdsUser.claims;
            if (rdsUser.participants) this.participants = rdsUser.participants;
        }
    }

    public getUserID(): string {
        return this.user_id;
    }

    public getSiteID(): string {
        return this.site_ID;
    }

    public getEmailAddr(): string {
        return this.emailAddr;
    }

    public setEmailAddr(addr): PulseAuth {
        this.emailAddr = addr || '';
        return this;
    }

    public getTableauUsername(): string {
        return this.tableau_username;
    }

    public doesSiteMatch(s: string): boolean {
        return s == this.site_ID;
    }

    public getType(): any {
        return this.type;
    }

    public isType(t: UserRole): boolean {
        return this.type == t;
    }

    public getAuthenticationID(): string {
        return this.authenticationID;
    }

    public getPreferredName(): string {
        return this.preferredName;
    }

    public getOrganizationID(): string {
        return this.organizationID;
    }

    public getStatus(): string {
        return this.status;
    }

    public isStatusInArray(ary: UserStatus[]): boolean {
        if (!ary) return false;
        for (const us of ary) {
            if (this.status == us) return true;
        }
        return false;
    }

    public isTypeInArray(ary: UserRole[]): boolean {
        if (!ary) return false;
        for (const urs of ary) {
            if (this.type == urs) return true;
        }
        return false;
    }

    public isBothPatientAndProxy(): boolean {
        return this.isPatientProxy;
    }

    public getMFARequired(): boolean {
        return this.mfa_required;
    }

    public getMFAStatus(): string {
        return this.mfa_status;
    }

    public getGreenlightPatientId(): string {
        return this.greenlight_patient_ID;
    }

    public getRegistryKey(): string {
        return this.registryKey;
    }

    public getParticipants(): any[] {
        return this.participants;
    }

    public getClaims(): any {
        return this.claims;
    }

    public static NotLoggedIn: PulseAuth = new PulseAuth(null, null);
}

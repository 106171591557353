import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SurveyService } from '@h20-services/survey.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-element-designer',
    templateUrl: './element-designer.component.html',
    styleUrls: ['./element-designer.component.scss'],
})
export class ElementDesignerComponent implements OnInit {
    constructor(
        private http: HttpClient,
        private surveyService: SurveyService
    ) {}
    @Input() elements: any[]; //all elements
    @Input() index: number; //this element
    @Output() elementsChange = new EventEmitter<any[]>();
    @Output() eventChange = new EventEmitter<any[]>();
    initialData: any;

    designerForm;

    ELEMENT_FORM = 'assets/survey-builder/element-forms/element.json';
    CUSTOM_ELEMENT_FIELDS = {
        checkbox: 'assets/survey-builder/element-forms/checkbox.json',
        comment: 'assets/survey-builder/element-forms/comment.json',
        datepicker: 'assets/survey-builder/element-forms/datepicker.json',
        dropdown: 'assets/survey-builder/element-forms/dropdown.json',
        'gesture-map-input': 'assets/survey-builder/element-forms/gesture-map.json',
        gesture: 'assets/survey-builder/element-forms/gesture.json',
        html: 'assets/survey-builder/element-forms/html.json',
        'link-generator': 'assets/survey-builder/element-forms/link-generator.json',
        multipletext: 'assets/survey-builder/element-forms/multipletext.json',
        paneldynamic: 'assets/survey-builder/element-forms/paneldynamic.json',
        'partial-date': 'assets/survey-builder/element-forms/partial-date.json',
        radiogroup: 'assets/survey-builder/element-forms/radiogroup.json',
        rating: 'assets/survey-builder/element-forms/rating.json',
        text: 'assets/survey-builder/element-forms/text.json',
        file: 'assets/survey-builder/element-forms/fileupload.json',
        phone: 'assets/survey-builder/element-forms/phonenumber.json',
    };
    LAYOUT_FORM = 'assets/survey-builder/element-forms/layout.json';
    VALIDATORS_FORM = 'assets/survey-builder/element-forms/validators.json';

    options = {
        condensed: true,
    };

    ngOnInit(): void {
        let elementForms = [];
        this.initialData = this.elements[this.index];

        firstValueFrom(this.http.get(this.ELEMENT_FORM))
            .then((elementForm) => {
                elementForms.push(elementForm);
                if (this.CUSTOM_ELEMENT_FIELDS[this.elements[this.index].type])
                    return firstValueFrom(
                        this.http.get(this.CUSTOM_ELEMENT_FIELDS[this.elements[this.index].type])
                    );
            })
            .then((customElementFields) => {
                if (customElementFields) {
                    elementForms.push(customElementFields);
                }
                return firstValueFrom(this.http.get(this.LAYOUT_FORM));
            })
            .then((layoutForm) => {
                elementForms.push(layoutForm);
                return firstValueFrom(this.http.get(this.VALIDATORS_FORM));
            })
            .then((valForm) => {
                elementForms.push(valForm);
                //cut out element form and validator for html
                if (this.elements[this.index].type == 'html') {
                    elementForms = elementForms.slice(1, 2);
                }
                this.designerForm = this.surveyService.concatenateSurveys(
                    'Element Form',
                    elementForms
                );
            });
    }

    onFormChanges(event: any) {
        this.elements[this.index] = Object.assign(
            this.elements[this.index],
            this.nonNullValues(event.value)
        );
        this.elementsChange.emit(this.elements);
        this.eventChange.emit(event);
    }
    nonNullValues(obj: any): any {
        return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null));
    }
}

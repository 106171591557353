<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="w-100 row pi-control-form-mode">
        <label
            class="form-label"
            [ngClass]="
                !element.orientation || element.orientation === 'horizontal'
                    ? 'col-12 col-md-6 col-lg-8'
                    : 'col-12'
            "
            [attr.for]="this.elementUID"
        >
            <app-element-title [element]="element"></app-element-title>
        </label>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="col-12 row row-cols-sm-auto">
                <div class="input-group">
                    <input
                        class="p-1 form-control"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        appMaskInput
                        [name]="this.elementUID"
                        [attr.aria-label]="element.title"
                        [placeholder]="element.placeHolder || selectedDateFormat"
                        (dateSelect)="onDateSelect($event)"
                        [dateFormat]="selectedDateFormat"
                        maxlength="10"
                        (click)="d.toggle()"
                        name="dobInput"
                        [(ngModel)]="datePickerValue"
                        [markDisabled]="disabled || element?.readOnly"
                        [minDate]="minDropdownDate"
                        [maxDate]="maxDropdownDate"
                        (ngModelChange)="onDateChanged($event)"
                    />
                    <button
                        class="btn btn-outline-secondary calendar-border"
                        (click)="d.toggle()"
                        type="button"
                    >
                        <i class="fa fa-calendar fa-sm" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>

        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>

<nav class="sticky-top pif-nav" id="pif-nav">
    <div class="d-flex align-items-center position-relative pif-nav-holder">
        <div class="sidebar me-2">
            <app-layout-sidebar></app-layout-sidebar>
        </div>
        <div class="pif-nav__logo">
            <a routerLink="/home">
                <img id="header_logo" src="/assets/image/composerLogo.png" alt="Logo" />
            </a>
        </div>
        <div class="col-2 p-3">
            <label class="col-12 px-0">
                Source Environment
                <select
                    class="form-control"
                    id="source_env"
                    (change)="updateSession($event, 'source')"
                >
                    <option [selected]="!selectedSourceEnv" value="" disabled>
                        --Please select--
                    </option>
                    <option
                        *ngFor="let env of sourceEnvs"
                        [value]="env"
                        [selected]="env === selectedSourceEnv"
                    >
                        {{ env }}
                    </option>
                </select>
                <div *ngIf="!sourceIsSelected" class="formError text-danger">
                    Please select a source env
                </div>
            </label>
        </div>
        <div class="col-2 p-3">
            <label class="col-12 px-0">
                Destination Environment
                <select
                    class="form-control"
                    id="destination_env"
                    (change)="updateSession($event, 'destination')"
                >
                    <option value="" [selected]="!selectedDestinationEnv" disabled>
                        --Please select--
                    </option>
                    <option
                        *ngFor="let env of destinationEnvs"
                        [value]="env"
                        [selected]="env === selectedDestinationEnv"
                    >
                        {{ env }}
                    </option>
                </select>
                <div *ngIf="!destinationIsSelected" class="formError text-danger">
                    Please select a destination env
                </div>
            </label>
        </div>
        <div class="col-2 p-3">
            <label class="col-12 px-0">
                Registry:
                <select class="form-control" (change)="updateSession($event, 'registry')">
                    <option value="" [selected]="!registryIsSelected" disabled>
                        --Please select--
                    </option>
                    <option
                        *ngFor="let reg of listRegistries"
                        [value]="reg"
                        [selected]="reg === selectedRegistry"
                    >
                        {{ reg }}
                    </option>
                </select>
                <div *ngIf="!registryIsSelected" class="formError text-danger">
                    Please select a registry
                </div>
            </label>
        </div>

        <div class="d-flex align-items-center ms-auto noSmall">
            <div class="font-family-headers font-size-18">Welcome! {{ userPreferredName }}</div>
            <app-button (action)="logout()" class="mx-3" [buttonText]="'Registry.Logout'" />
        </div>
    </div>
</nav>

import { Component, OnInit } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
    constructor(private router: Router) {}
    ngOnInit(): void {
        $('body').addClass('public-view');
        Auth.signOut();
    }

    goLogIn() {
        this.router.navigate(['/login']);
    }
}

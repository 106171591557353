import { Component, Injector, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { CDNService } from '@h20-services/cdn.service';
import { CommonService } from '@h20-services/common.service';
import { IFormFile } from '@h20-services/models/forms/types/IFormFile';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { FileManagerService } from '@h20-services/file-manager.service';
import { IFileData, IFileStatus, IFileWithMetaData } from '@h20-services/models/IFileWithMetaData';
import { UserService } from '@h20-services/user.service';
import { ParticipantService } from '@h20-services/participant.service';
import { DataEntryRoles, UserRole } from '@h20-services/models/role';
import { AuthService } from '@h20-services/auth.service';
import { IFormEntryType } from '@h20-services/models/forms/types/IFormEntryType';
import { UuidService } from '@h20-services/uuid.service';

@Component({
    selector: 'app-pi-control-filepicker',
    templateUrl: './pi-control-filepicker.component.html',
    styleUrls: ['./pi-control-filepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlFilepickerComponent,
        },
    ],
})
export class PiControlFilepickerComponent
    extends AbstractPiControlComponent<IFormFile, any>
    implements AfterViewInit
{
    @Input() element: IFormFile;
    @Input() formData: any;
    @Input() mode: string;
    siteId;
    userId;
    @Input() entryType: IFormEntryType;
    @Output() stageFileUploadChanges: EventEmitter<any> = new EventEmitter();
    @Output() stageFileDeleteChanges: EventEmitter<any> = new EventEmitter();

    selectedFiles = [];
    filesWithMetaData = [];
    isRestrictedDownload = false;
    singleFileUploaded = false;
    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private fileManageSvc: FileManagerService,
        private userSvc: UserService,
        private prtSvc: ParticipantService,
        private auth: AuthService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    async ngAfterViewInit() {
        if (!!this.selectedFiles) {
            if (!!this.formData) {
                this.userId = this.formData.id;
                this.siteId = this.formData.site_ID || this.formData.siteID;
                this.fileManageSvc
                    .getFileDataById(this.selectedFiles)
                    .subscribe((files: IFileData[]) => {
                        if (this.entryType === IFormEntryType.clinicalEntry) {
                            files = files.filter((file) => file.status !== IFileStatus.Deleted);
                        }
                        files = files.filter(
                            (file) =>
                                (file.question_key && file.question_key === this.element.name) ||
                                !file.question_key
                        );
                        this.filesWithMetaData = [...files];
                    });
            } else {
                this.userSvc
                    .getUser(this.prtSvc.getCurrentParticipant())
                    .subscribe(async (users) => {
                        this.userId = users[0].id;
                        this.siteId = users[0].site_ID;

                        this.fileManageSvc
                            .getFileDataById(this.selectedFiles)
                            .subscribe((files: IFileData[]) => {
                                files = files.filter(
                                    (file) =>
                                        (file.question_key &&
                                            file.question_key === this.element.name) ||
                                        !file.question_key
                                );
                                this.filesWithMetaData = [...files];
                            });
                    });
            }
            // TODO: Might be able to make this configurable
            if ((await this.auth.getPulseAuth()).isType(UserRole.Pv)) {
                this.isRestrictedDownload = true;
            }
        }
    }

    setStringValue() {
        this.selectedFiles = [];

        this.stringValue = String(this._value);

        if (this._value) {
            if (Array.isArray(this._value)) {
                this._value.forEach((element) => {
                    this.selectedFiles.push(element);
                });
            } else {
                this.selectedFiles.push(this._value);
            }
        }
    }

    selectFile_TrackingChanges(files: any[]) {
        for (const file of files) {
            file.uploaded = this.mode === 'track-changes';

            this.filesWithMetaData.push({
                name: file.name,
            });
            file['elementName'] = this.element.name;
        }
        this.stageFileUploadChanges.emit(files);
    }

    async selectFile(event) {
        try {
            this.selectedFiles = this.selectedFiles === null ? [] : this.selectedFiles;

            if (this.mode === 'track-changes') {
                this.selectFile_TrackingChanges(event.target.files);
            } else {
                const file_promises = [];
                for (const file of event.target.files) {
                    this.filesWithMetaData.push(file);
                    file_promises.push(this.processFile(file));
                }
                await Promise.allSettled(file_promises);
            }

            this.handleValueChange();
        } catch (error) {
            throw error;
        }
    }

    handleValueChange() {
        this.markAsTouched();
        this._value = this.filesWithMetaData.map((f) => {
            return f.id || f.name || f;
        });
        this.notifyValueChange();
    }

    async processFile(file: any) {
        // TODO: handle case where same file name is uploaded
        if (this.selectedFiles.find((f) => f === file.name))
            throw new Error('Duplicate file name selected');

        file.uploading = true;

        return this.fileManageSvc
            .uploadFileToManager(
                file,
                this.element.name,
                'partial',
                this.formData.submission_id,
                await this.getTaskIdFromRoute(),
                this.formData.patient_ID,
                null
            )
            .then((file_data: any) => {
                this.stageFileUploadChanges.emit(file_data);

                if (!!file_data) {
                    file.uploading = false;
                    file.uploaded = true;
                }

                this.singleFileUploaded = true;
                file.id = file_data.id;
                return file_data.id;
            });
    }

    async removeFile(item: any) {
        //remove from the control's value list
        if (this.mode === 'track-changes') {
            this.stageFileDeleteChanges.emit(item);
            this.filesWithMetaData = this.filesWithMetaData.filter(function (element) {
                return element !== item;
            });
        } else {
            this.filesWithMetaData = this.filesWithMetaData.filter(function (element) {
                return element !== item;
            });
            this.fileManageSvc.deleteFile(item.id).subscribe((success) => {
                if (success) item.status = IFileStatus.Deleted;
                // this.fileManageSvc.updateFileMetaData(file, this.siteId, this.userId);
            });
        }

        this.selectedFiles = this.filesWithMetaData.map((file) => {
            return file.fileName || file.id;
        });
        this.singleFileUploaded = false;
        this.markAsTouched();
        this._value = this.selectedFiles;
        this.notifyValueChange();
    }

    getTaskIdFromRoute(): string {
        let taskId;
        this.activatedRoute.params.subscribe((params: Params) => {
            if (window.location.toString().includes('/surveys/') && params.task_id) {
                taskId = params.task_id;
            } else if (window.location.toString().includes('/profile/')) {
                taskId = '';
            } else {
                if (this.formData?.task_id) taskId = this.formData.task_id;
                else {
                    console.error('Route params without task id');
                    taskId = null; // taskId should be null in case of paper consent
                }
            }
        });
        return taskId;
    }

    getSurveyIdFromRoute(): string {
        let surveyId;
        this.activatedRoute.params.subscribe((params: Params) => {
            if (window.location.toString().includes('/surveys/') && params.survey_id) {
                surveyId = params.survey_id;
            } else if (window.location.toString().includes('/profile/')) {
                surveyId = '';
            } else {
                if (this.formData?.survey_ID) surveyId = this.formData.survey_ID;
                else {
                    console.error('Route params without survey id');
                    surveyId = 'invalid_survey_ID';
                }
            }
        });
        return surveyId;
    }

    getPatientIdFromRoute(): string {
        let patientId;
        this.activatedRoute.params.subscribe((params: Params) => {
            if (window.location.toString().includes('/patients/') && params.patient_id) {
                patientId = params.patient_id;
            } else {
                if (this.formData?.id) patientId = this.formData.id;
                else {
                    console.error('Route params without patient id');
                    patientId = 'invalid_patient_ID';
                }
            }
        });
        return patientId;
    }

    @Output() downloadButtonClicked: EventEmitter<string> = new EventEmitter<string>();

    downloadEventHandlerFunction(item: any) {
        this.downloadButtonClicked.emit(`{"fileName": "${item}", "key": "${this.element.name}"}`);
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@h20-services/auth.service';
import { CommonService } from '@h20-services/common.service';
import { ConfigService } from '@h20-services/config.service';
import { EnvironmentService } from '@h20-services/environment.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';

@Component({
    selector: 'app-layout-nav',
    templateUrl: './layout-nav.component.html',
    styleUrls: ['./layout-nav.component.scss'],
})
export class LayoutNavComponent implements OnInit {
    userPreferredName: string;
    constructor(
        private router: Router, 
        private auth: AuthService, 
        private commonSvc: CommonService,
        private environmentSvc: EnvironmentService,
        private config_svc: ConfigService,
    ) {}

    
    sourceEnvs = this.commonSvc.listSourceEnvs();
    destinationEnvs = this.commonSvc.listTargetEnvs();

    sourceIsSelected = false;
    destinationIsSelected = false; 
    registryIsSelected = false;

    selectedSourceEnv: string;
    selectedDestinationEnv: string; 
    selectedRegistry: string;

    //registry selector
    allRegistries: any[];
    listRegistries: string[];
        

    ngOnInit(): void {
        this.auth
            .getPulseAuth()
            .then((user: PulseAuth) => {
                this.userPreferredName = user.getPreferredName();
            })
            .catch((err) => {
                console.error(err);
            });
            this.config_svc
            .listRegistryConfig()
            .toPromise()
            .then((regRes) => {
                this.listRegistries = regRes
                    .filter((reg) =>reg.status === "active")
                    .map((reg) => reg.registry_id);
            });

        this.environmentSvc.currentSource.subscribe( (environment) => this.selectedSourceEnv = environment);
        this.environmentSvc.currentDestination.subscribe( (environment) => this.selectedDestinationEnv = environment);
        this.environmentSvc.currentRegistry.subscribe((registry) => this.selectedRegistry = registry);

        if(this.selectedSourceEnv) this.sourceIsSelected = true;
        if(this.selectedDestinationEnv) this.destinationIsSelected = true;
        if(this.selectedRegistry) this.registryIsSelected = true;
    }

    logout(): void {
        this.router.navigate(['/logout']);
    }

    updateSession(event: Event, whichEnv: string): void {
        const selectElement = event.target as HTMLSelectElement;
        const value = selectElement.value;

        if(whichEnv === "source"){
            this.sourceIsSelected = true;
            this.environmentSvc.changeSource(value);
        } else if(whichEnv === "destination"){
            this.destinationIsSelected = true;
            this.environmentSvc.changeDestination(value);
        } else if(whichEnv === "registry"){
            this.registryIsSelected = true;
            this.environmentSvc.changeRegistry(value);
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DDBEmailTemplates } from '@h20-services/ddb-email.service';
import { Template } from '@h20-services/models/emails/email_template';
import { ConfigService } from '@h20-services/config.service';
import { SiteService } from '@h20-services/site.service';
import { TemplateTypes } from '../../../h20-services/models/emails/email_template_types';

@Component({
    selector: 'app-modify-templates',
    templateUrl: './modify-templates.component.html',
    styleUrls: ['./modify-templates.component.scss'],
})
export class ModifyTemplatesComponent implements OnInit {
    //Params: Params relates to the selected template being edited, if no template it selected, params refers to the current template being created.
    preferred_language: string = '';
    split_primary_key: string[];
    preview_html_panel: boolean = false;
    preview_html: string;
    body_html: string;
    subject: string;
    isNewTemplate: boolean;
    template: Template;
    lstRegistries: string[] = [];
    lstSites: string[] = [];
    lstOrganizations: string[] = ['default'];
    lst_template_types: string[] = [];
    lst_languages: Set<string> = new Set<string>();
    primary_key: string;

    selectedRegistry: any;
    selectedSite: string;
    selectedOrganization: string;
    selected_template_type: TemplateTypes;
    selected_language: string;

    reply_to_address: string = 'no-reply@healthie.net';
    sender_email: string = 'no-reply@healthie.net';

    registryRequired: boolean = false;
    validationError: boolean = false;

    loading: boolean = true;
    submitLoading: boolean = false;

    blurred: boolean = false;
    focused: boolean = false;

    disabled: boolean = false;
    duplicate: boolean;

    constructor(
        private ddb_email_svc: DDBEmailTemplates,
        private route: ActivatedRoute,
        private router: Router,
        private config_svc: ConfigService,
        private site_svc: SiteService
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            if (params.name) {
                this.isNewTemplate = false;
                this.disabled = true;
                this.loadInitialData(
                    params['registry_id#organization#site_id#template#language']
                ).then(() => {
                    if (this.template.subject == null) {
                        this.template.subject = '';
                    }
                    if (this.template.body == null) {
                        this.template.body = '';
                    }

                    if (this.selectedOrganization && this.selectedRegistry && this.selectedSite) {
                        this.body_html = this.template.body;
                        this.subject = this.template.subject;
                        this.loading = false;
                    }
                });
            } else {
                this.isNewTemplate = true;
                this.template = new Template('', '', '', '', '', '');
                this.loadInitialData().then(() => {
                    if (this.lstRegistries && this.lstOrganizations && this.lstSites) {
                        this.loading = false;
                    }
                });
            }
        });
    }

    togglePreviewPanel(): void {
        this.loadPreview(this.template);
        this.preview_html_panel = !this.preview_html_panel;
        if (this.preview_html_panel) {
            this.loadPreview(this.template);
        }
    }

    adjustTextareaHeight(event: Event): void {
        const textarea = event.target as HTMLTextAreaElement;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }

    async loadInitialData(pkey?: string): Promise<void> {
        if (pkey) {
            this.primary_key = pkey;
            this.split_primary_key = pkey.split('#');
            this.selectedRegistry = this.split_primary_key[0];
            this.selectedOrganization = this.split_primary_key[1];
            this.selectedSite = this.split_primary_key[2];
            this.selected_template_type = this.split_primary_key[3] as TemplateTypes;
            this.selected_language = this.split_primary_key[4];

            await this.loadRegistries();
            await this.loadTemplate(pkey);
            await this.loadSites();
            await this.loadTemplateTypes();
            await this.loadLanguages();
        } else {
            await this.loadRegistries();
            await this.loadSites();
            await this.loadTemplateTypes();
        }
    }

    async loadRegistries(): Promise<void> {
        try {
            const res = await this.config_svc.listRegistryConfig().toPromise();
            this.lstRegistries = res
                .filter((reg) => {
                    return (
                        reg.registry_id.toString() !== 'proto-ui-copy' &&
                        reg.registry_id.toString() !== 'disabled-curve' &&
                        reg.registry_id.toString() !== 'disabled-grasp'
                    );
                })
                .map((reg) => reg.registry_id.toString());
            this.lstRegistries.push('master');
        } catch (error) {
            console.error('Error loading registries:', error);
        }
    }

    async loadSites(): Promise<void> {
        try {
            if (this.selectedRegistry !== 'master') {
                const res = await this.site_svc
                    .getSitesByRegistry(this.selectedRegistry)
                    .toPromise();
                this.lstSites = res.map((site) => site.name);
                this.lstSites.push('master');
            } else {
                this.lstSites.length = 0;
                this.lstSites.push('master');
            }
        } catch (err) {
            console.error(err);
        }
    }

    async loadTemplateTypes(): Promise<void> {
        return this.ddb_email_svc
            .listTemplateTypes()
            .toPromise()
            .then((template) => {
                this.lst_template_types = template;
            });
    }

    async loadTemplate(pkey: string): Promise<void> {
        return this.ddb_email_svc
            .getTemplate(pkey)
            .toPromise()
            .then((template) => {
                this.template = template;
            });
    }

    async getTemplate(pkey: string): Promise<any> {
        return this.ddb_email_svc.getTemplate(pkey).toPromise();
    }

    async loadLanguages(): Promise<void> {
        this.lst_languages.clear();
        this.ddb_email_svc.listTemplates().subscribe((templates) => {
            templates
                .filter((t) => {
                    return t['registry_id#organization#site_id#template#language'].includes(
                        this.selectedRegistry
                    );
                })
                .map((t) => {
                    const parts: string[] =
                        t['registry_id#organization#site_id#template#language'].split('#');
                    this.lst_languages.add(parts[parts.length - 1]);
                });
        });
    }

    public loadPreview(template) {
        template['registry_id#organization#site_id#template#language'] =
            `${this.selectedRegistry}#${this.selectedOrganization}#${this.selectedSite}#${this.selected_template_type}#${this.selected_language}`;

        const preview = {
            ...template,
            template_type: this.selected_template_type,
            template_data: {
                registry: this.selectedRegistry,
            },
        };
        preview.body = this.body_html;
        preview.subject = this.subject;
        return this.ddb_email_svc
            .previewHTML(preview)
            .toPromise()
            .then((res) => {
                this.preview_html = res;
            });
    }

    async saveTemplate(): Promise<void> {
        this.submitLoading = true;
        this.template.body = this.body_html;
        this.template['registry_id#organization#site_id#template#language'] =
            `${this.selectedRegistry}#${this.selectedOrganization}#${this.selectedSite}#${this.selected_template_type}#${this.selected_language}`;
        if (this.isNewTemplate) {
            await this.ddb_email_svc.createTemplate(this.template).toPromise();
        } else {
            await this.ddb_email_svc.updateTemplate(this.template).toPromise();
        }
        this.submitLoading = false;
        this.router.navigate(['/emailtemplatemanager']);
    }

    async submit(): Promise<void> {
        this.validationError = false;
        this.registryRequired = false;

        this.template = new Template(
            this.template.header,
            this.template.footer,
            this.template.body,
            this.template.subject,
            this.selectedRegistry,
            this.primary_key
        );

        if (this.selectedRegistry && this.selectedSite && this.selectedOrganization) {
            await this.saveTemplate();
        } else {
            this.registryRequired = true;
        }
    }

    //Since we only have default as the organization, we don't currently have any implementation for loading the organization
    async registryChange(value: any): Promise<void> {
        this.selectedRegistry = value;
        await this.loadSites();
        await this.loadLanguages();
        if (
            this.selectedOrganization &&
            this.selectedSite &&
            this.selected_language &&
            this.selected_template_type
        ) {
            try {
                await this.loadPreview(this.template);
            } catch (error) {
                console.error('Error: ', error);
            }
        }
    }

    async onOrgChange(value: any) {
        this.selectedOrganization = value;
    }

    async onBodyChange(value: any) {
        let opening_tag: string = `<td bgcolor="#FFFFFF" style="background-color: #ffffff; padding: 10px 21px 22px 21px">`;
        let closing_tag: string = `</td>`;
        this.body_html = opening_tag + value.html + closing_tag;
        this.loadPreview(this.template);
    }

    async onSiteChange(value: any) {
        this.selectedSite = value;
    }

    async onTemplateTypeChange(value: any) {
        this.selected_template_type = value;
        if (
            this.selectedOrganization &&
            this.selectedSite &&
            this.selected_language &&
            this.selected_template_type
        ) {
            const pkey = `${this.selectedRegistry}#${this.selectedOrganization}#${this.selectedSite}#${this.selected_template_type}#${this.selected_language}`;
            try {
                const template = await this.getTemplate(pkey);
                if (!template) {
                    this.duplicate = false;
                    throw new Error();
                }
                this.duplicate = true;
                this.template = template;
                this.subject = this.template.subject;
                this.body_html = this.template.body;
                await this.loadPreview(this.template);
            } catch {
                this.template.body = '';
                this.template.subject = '';
            }
        }
    }

    async onLanguageChange(value: any) {
        this.selected_language = value;
        if (
            this.selectedOrganization &&
            this.selectedSite &&
            this.selected_language &&
            this.selected_template_type
        ) {
            const pkey = `${this.selectedRegistry}#${this.selectedOrganization}#${this.selectedSite}#${this.selected_template_type}#${this.selected_language}`;
            const template = await this.getTemplate(pkey);
            if (template) {
                this.duplicate = true;
                this.template = template;
                this.subject = this.template.subject;
                this.body_html = this.template.body;
                await this.loadPreview(this.template);
            } else {
                this.duplicate = false;
                this.template.body = '';
                this.template.subject = '';
            }
        }
    }

    async onSubjectChange(value: any) {
        this.subject = value;
        await this.loadPreview(this.template);
    }
}

<div class="preview-button-container" [ngClass]="{ shifted: preview_html_panel }" *ngIf="!loading">
    <app-button
        [iconConfig]="{ iconClasses: preview_html_panel ? 'fa-solid fa-caret-left mx-1' : 'fa-solid fa-caret-right mx-1'}"
        (click)="togglePreviewPanel()"
        [title]="preview_html_panel ? 'Close' : 'Preview'"
        [buttonText]="preview_html_panel ? 'Close' : 'Preview'"
        [ariaLabel]="preview_html_panel ? 'Close' : 'Preview'"
        buttonTextSize="var(--font-size-21)" 
    />
</div>
<div class="update-container" [ngClass]="{ shifted: preview_html_panel }">
    <h1 *ngIf="!isNewTemplate" class="text-center mt-3 mb-3">Update Template</h1>
    <h1 *ngIf="isNewTemplate" class="text-center mt-3 mb-3">Create Template</h1>
    <div *ngIf="loading">Loading...</div>
    <form *ngIf="!loading" id="updateForm">
        <a id="dupe_text" *ngIf="duplicate"
            ><strong
                >WARNING: This template already exists, you will be overriding the existing template
                once you save</strong
            ></a
        >
        <div class="info">
            <label for="registry">Registry</label>
            <select
                id="registry"
                name="registry"
                [(ngModel)]="selectedRegistry"
                (change)="registryChange($event.target.value)"
                [disabled]="disabled"
            >
                <option *ngFor="let registry of lstRegistries" [value]="registry">
                    {{ registry }}
                </option>
            </select>
            <label for="organization">Organization</label>
            <select
                id="organization"
                name="organization"
                [(ngModel)]="selectedOrganization"
                (change)="onOrgChange($event.target.value)"
                [disabled]="disabled"
            >
                <option *ngFor="let org of lstOrganizations" [value]="org">{{ org }}</option>
            </select>
            <label for="site">Site</label>
            <select
                id="site"
                name="site"
                [(ngModel)]="selectedSite"
                (change)="onSiteChange($event.target.value)"
                [disabled]="disabled"
            >
                <option *ngFor="let site of lstSites" [value]="site">{{ site }}</option>
            </select>
            <label for="temp_types">Template Types</label>
            <select
                id="temp_types"
                name="temp_types"
                [(ngModel)]="selected_template_type"
                (change)="onTemplateTypeChange($event.target.value)"
                [disabled]="disabled"
            >
                <option *ngFor="let temp of lst_template_types" [value]="temp">{{ temp }}</option>
            </select>
            <label for="languages">Languages</label>
            <select
                id="languages"
                name="languages"
                [(ngModel)]="selected_language"
                (change)="onLanguageChange($event.target.value)"
                [disabled]="disabled"
            >
                <option *ngFor="let lang of lst_languages" [value]="lang">{{ lang }}</option>
            </select>
        </div>
        <div class="form-fields">
            <label for="subject">Subject</label>
            <div id="editor">
                <textarea
                    type="text"
                    id="subject"
                    name="subject"
                    class="form-control"
                    [(ngModel)]="template.subject"
                    (input)="adjustTextareaHeight($event)"
                    (change)="onSubjectChange($event.target.value)"
                ></textarea>
            </div>

            <div>
                <label for="body">Body</label>
                <quill-editor
                    [ngModel]="template.body"
                    [styles]="{ height: '200px' }"
                    [ngModelOptions]="{ standalone: true }"
                    (onContentChanged)="onBodyChange($event)"
                ></quill-editor>
            </div>
        </div>
        <div class="form-actions">
            <button class="submit-button" (click)="submit()">Submit</button>
        </div>
    </form>
</div>
<div class="preview-panel" [ngClass]="{ open: preview_html_panel }">
    <div class="inner-html" [innerHTML]="preview_html"></div>
</div>

<div class="container-fluid">
    <!-- Done panel editor -->
    <div>
        <app-element-layout-designer [(elements)]="panel.elements"  
            (eventChange)="onEventChanges($event)"> 
        </app-element-layout-designer>
    </div>
    <div class="accordion accordion-flush" id="panelAccordion">
        <div class="accordion-item border-0">
            <div class="accordion-header" id="panelAccordion-heading">
                <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelAccordion-collapse"
                    aria-expanded="false"
                    aria-controls="panelAccordion-collapse"
                >
                    <div class="w-100 d-flex justify-content-end align-items-center">
                        <div class="pe-3">Edit Section Details</div>
                    </div>
                </button>
            </div>
            <div
                id="panelAccordion-collapse"
                class="accordion-collapse collapse"
                aria-labelledby="panelAccordion-heading"
                data-bs-parent="#panelAccordion"
            >
                <div class="accordion-body">
                    <app-pi-form
                        *ngIf="panelForm"
                        [formData]="panel"
                        [formDef]="panelForm"
                        layout="flat"
                        (formChanges)="onPanelFormChanges($event)"
                    ></app-pi-form>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <h1 class="mb-0 mr-3">Thankyou Page:</h1>
                        <div *ngIf="!selectedRegistry" class="formError text-danger mx-3">
                            Please select a registry
                        </div>
                        <app-button
                            *ngIf="isInit" 
                            class="mx-3" 
                            (action)="saveThankyouContent()"
                            [buttonText]="'Registry.Save'"
                        />
                    </div>
                    <app-loading-component
                        [loading]="saving"
                    ></app-loading-component>
                    <hr />

                    <app-loading-component [loading]="loading">
                        <div *ngIf="isInit" class="card">
                            <div
                                class="card-body text-center h2 font-weight-bold"
                            >
                                <i
                                    attr.aria-label="'Icons.Loading'"
                                    class="fad fa-trophy-alt"
                                ></i>
                                'ThankYou.ThankYou' | translate }}
                                <i
                                    attr.aria-label="'Icons.Loading'"
                                    class="fad fa-trophy-alt"
                                ></i>
                            </div>
                            <span class="font-italic"
                                >Renders if value set AND calculated PROSCORE is
                                not 0</span
                            >
                            <div class="text-center">
                                <div class="card">
                                    <div class="card-body">
                                        <div
                                            class="h4 p-2"
                                            [innerHTML]="summaryScoreShort"
                                        ></div>
                                        <textarea
                                            class="form-control"
                                            placeholder="summaryScoreShort"
                                            [value]="summaryScoreShort"
                                            (change)="
                                                onTextChange(
                                                    'summaryScoreShort',
                                                    'default',
                                                    $event
                                                )
                                            "
                                        ></textarea>
                                        <br />
                                        <div class="h3 font-weight-bold">
                                            <span class="font-italic"
                                                >PROSCORE: </span
                                            >{{ PROScore }}
                                        </div>
                                        <br />
                                        <div
                                            class="text-left p-2"
                                            [innerHTML]="summaryScoreLong"
                                        ></div>
                                        <textarea
                                            class="form-control"
                                            placeholder="summaryScoreLong"
                                            [value]="summaryScoreLong"
                                            (change)="
                                                onTextChange(
                                                    'summaryScoreLong',
                                                    'default',
                                                    $event
                                                )
                                            "
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <span class="font-italic"
                                    >Renders if value set</span
                                >
                                <div class="p-2" [innerHTML]="content1"></div>
                                <textarea
                                    class="form-control"
                                    placeholder="content1"
                                    [value]="content1"
                                    (change)="
                                        onTextChange(
                                            'content1',
                                            'default',
                                            $event
                                        )
                                    "
                                ></textarea>
                                <br />
                                <span class="font-italic"
                                    >Renders if value set</span
                                >
                                <div class="p-2" [innerHTML]="content2"></div>
                                <textarea
                                    class="form-control"
                                    placeholder="content2"
                                    [value]="content2"
                                    (change)="
                                        onTextChange(
                                            'content2',
                                            'default',
                                            $event
                                        )
                                    "
                                ></textarea>
                                <br />
                                <div class="text-center">
                                    <span class="i"
                                        >Buttons render by claim</span
                                    >
                                    <app-button 
                                    class="m-2"
                                    [buttonText]="'Registry.Signup'"
                                    />
                                    <app-button 
                                    class="m-2"
                                    [buttonText]="'Registry.Dashboard'"
                                    />
                                    <app-button 
                                    class="m-2"
                                    [buttonText]="'Registry.Library'"
                                    />
                                    <app-button 
                                    class="m-2"
                                    [buttonText]="'Tasks.NextTask' + getText(nextTask.label)"
                                    />
                                </div>
                            </div>
                            <span class="font-italic"
                                >Renders if value set AND has more tasks</span
                            >
                            <div class="text-center p-2 h3">
                                {{ taskCompleteMsg }}
                                <i
                                    attr.aria-label="'Icons.Star'"
                                    class="fas fa-star"
                                ></i>
                            </div>
                            <textarea
                                class="form-control"
                                placeholder="taskCompleteMsg"
                                [value]="taskCompleteMsg"
                                (change)="
                                    onTextChange(
                                        'taskCompleteMsg',
                                        'default',
                                        $event
                                    )
                                "
                            ></textarea>
                        </div>
                    </app-loading-component>
                </div>
            </div>
        </div>
    </div>
</div>
